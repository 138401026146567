import { Show } from 'src/app/interfaces/show.interface';
import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {finalize, tap} from "rxjs/operators";
import { HttpClient, HttpEventType } from "@angular/common/http";
import {Subscription} from "rxjs";
import {ShowsService} from "../../services/shows.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatabaseService } from 'src/app/services/database.service';

@Component({
  selector: 'app-repush-popup',
  templateUrl: './repush-popup.component.html',
  styleUrls: ['./repush-popup.component.scss']
})
export class RepushPopupComponent implements OnInit {

	public title = '';
	public show!: Show;
	text: any;
	processing = false;
	processing2 = false;
	
	constructor(private dialogRef: MatDialogRef<RepushPopupComponent>,
							@Inject(MAT_DIALOG_DATA) data:any,
							private http: HttpClient,
							public showsService: ShowsService,
							private db: DatabaseService,
							private _snackBar: MatSnackBar) {
		this.title = data.title;
		this.show = data.show ?? '';
	}

	ngOnInit(): void {

	}

	repush() {
		this.processing = true;
		this.db.setShowStatus(this.show.show_code, this.text).then(() => {
			this.processing = false;
			this._snackBar.open("Successfully Pushed to Firebase!");
		});
	}

	repush_cloudfront() {
		this.processing2 = true;
		this.showsService.updateShowJson(this.show, this.text).subscribe(() => {
			this.processing2 = false;
			this._snackBar.open("Successfully Pushed to Cloudfront!");
		});
	}
	
	close() {
		this.dialogRef.close();
	}

}
