<h2 class="popup-heading" mat-dialog-title>Video Preview of Show: {{ show.show_code }} <br> [{{ videoName }}]</h2>
<mat-dialog-content class="mat-typography">
    <div class="video">
        <video controls autoplay>
            <source [src]="show.video_url" type="video/mp4">
        </video>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="cancel-popup" mat-stroked-button (click) = "close()">Cancel</button>
</mat-dialog-actions>
