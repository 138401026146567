import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import {Observable} from "rxjs";
import {Brand} from "../interfaces/brand.interface";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BrandsService {
  constructor(private http:HttpClient) { }

  index(
    filters: any, orderBy = '', order = 'asc',
    pageNumber = 1, pageSize = 10):  Observable<any> {
		let params = new HttpParams();
  	Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/brands', {
      params: params
    });
  }

	get(id: number): Observable<Object>{
		return this.http.get(environment.apiUrl + '/brands/'+id);
	}

	store(brand: Brand): Observable<Object> {
		return this.http.post(environment.apiUrl + '/brands/', brand);
	}

	edit(brand: Brand): Observable<Object> {
		return this.http.put(environment.apiUrl + '/brands/'+brand.id, brand);
	}

	uploadImage(formData: any): Observable<any>{
		return this.http.put(environment.apiUrl + '/brands/'+formData.get("id"), formData, {
			reportProgress: true,
			observe: 'events'
		})
	}

	hide(id: number): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/brands/'+id+'/hide', {});
	}

	unhide(id: number): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/brands/'+id+'/unhide', {});
	}

	destroy(id: number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/brands/'+id);
	}
}
