import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatTableModule} from '@angular/material/table';
import { Show } from 'src/app/interfaces/show.interface';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { ShowsService } from 'src/app/services/shows.service';

export interface PeriodicElement {
  parameter: string;
  details: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {parameter: 'Listeners', details: '22'},
  {parameter: 'Downloads', details: '12'},
  {parameter: 'Feedback', details: 'Dummy Text'},
];

@Component({
  selector: 'app-show-reporting',
  templateUrl: './show-reporting.component.html',
  styleUrls: ['./show-reporting.component.scss'],
  // standalone: true,
  // imports: [MatTableModule],
})
export class ShowReportingComponent implements OnInit {
  displayedColumns: string[] = ['parameter', 'details'];
  reportingSource = ELEMENT_DATA;
  show:any;
  processing = false;
  constructor(public showService: ShowsService,
    private dialogRef: MatDialogRef<ShowReportingComponent>,
    @Inject(MAT_DIALOG_DATA) data:any
      ) {
    this.show = data.show;
    console.log(this.show)
  }

  ngOnInit(): void {
  }

  close() {
		this.dialogRef.close();
	}
  downloadCsv() {
    if (!this.processing) {
      this.processing = true;
  
      // Assuming 'array' contains your data
      const array = this.reportingSource;
  
      // Define the headers
      const head = ['parameter', 'details'];
  
      // Use Angular2Csv to generate and download the CSV with headers
      new AngularCsv(array, this.show.custom_name);
  
    }
  }
}
