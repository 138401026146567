import {Component, AfterViewInit, ChangeDetectionStrategy, ViewChild, Inject} from '@angular/core';
import { LySliderChange, STYLES as SLIDER_STYLES } from '@alyle/ui/slider';
import { ThemeVariables, ThemeRef, lyl, StyleRenderer } from '@alyle/ui';
import {
	STYLES as CROPPER_STYLES,
	LyImageCropper,
	ImgCropperConfig,
	ImgCropperEvent,
	ImgCropperErrorEvent,
	ImgCropperLoaderConfig,
	ImgResolution
} from '@alyle/ui/image-cropper';
import { Platform } from '@angular/cdk/platform';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BrandsService} from "../../../services/brands.service";
import { HttpEventType } from "@angular/common/http";

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
	ref.renderStyleSheet(SLIDER_STYLES);
	ref.renderStyleSheet(CROPPER_STYLES);
	const slider = ref.selectorsOf(SLIDER_STYLES);
	const cropper = ref.selectorsOf(CROPPER_STYLES);

	return {
		root: lyl `{
      ${cropper.root} {
        max-width: 400px
        height: 320px
      }
      ${slider.root} {
        width: 100%
        max-width: 400px
        padding-left: 1em
        padding-right: 1em
      }
    }`,
		sliderContainer: lyl `{
      text-align: center
      max-width: 400px
      padding: 14px
      box-sizing: border-box
    }`
	};
};

@Component({
  selector: 'app-banner-editor',
  templateUrl: './banner-editor.component.html',
  styleUrls: ['./banner-editor.component.scss'],
	providers: [
		StyleRenderer
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerEditorComponent implements AfterViewInit {

	readonly classes = this.sRenderer.renderSheet(STYLES, 'root');
	croppedImage?: string;
	ready: boolean = false;
	scale: number = 1;
	minScale: number = 0;
	maxScale: number = 0;
	brand: any;
	field: string;
	processing = false;
	disablePreview = false;
	fileSelected = false;
	originalImg: any;
	imgLoaded = false;
	// @ts-ignore
	@ViewChild(LyImageCropper, { static: true }) cropper: LyImageCropper;
	myConfig: ImgCropperConfig = {
		// 3:1 aspect ratio
		width: 200 * 3,
		height: 200,
		keepAspectRatio: true,
		responsiveArea: true,
		extraZoomOut: true,
		//output: ImgResolution.OriginalImage,
	};

	constructor(
		private dialogRef: MatDialogRef<BannerEditorComponent>,
		readonly sRenderer: StyleRenderer,
		private _platform: Platform,
		@Inject(MAT_DIALOG_DATA) data: any,
		public brandService: BrandsService
	) {
		this.brand = data.brand;
		this.field = data.field
	}

	ngAfterViewInit() {
		if (this._platform.isBrowser) {
			if(this.brand.header_image) {
				const config: ImgCropperLoaderConfig = {
					originalDataURL: this.brand[this.field].replace('brands', 'brands/original'),
				};
				this.cropper.loadImage(config);
				//setTimeout(() => { this.cropper.center(); }, 1000);
			}
		}
	}
	zoomOut(){
		// @ts-ignore
		this.cropper._minScale = 0;
		// @ts-ignore
		this.cropper.setScale(this.cropper.scale - 0.05);
	}
	fileSelect($event: Event){
		this.fileSelected = true;
		this.cropper.selectInputEvent($event)
	}
	onCropped(e: ImgCropperEvent) {
		this.croppedImage = e.dataURL;
		console.log('cropped img: ', e);
	}
	onLoaded(e: ImgCropperEvent) {
		this.imgLoaded = true;
		if(this.fileSelected)
			this.originalImg = e.originalDataURL;
		console.log('img loaded', e);
		window.dispatchEvent(new Event('resize'));

	}
	onError(e: ImgCropperErrorEvent) {
		console.warn(`'${e.name}' is not a valid image`, e);
	}
	onSliderInput(event: LySliderChange) {
		this.scale = event.value as number;
	}
	save(){
		if(!this.croppedImage){
			this.disablePreview = true;
			this.cropper.crop();
		}
		this.processing = true;
		let formData = new FormData();
		formData.append("id", this.brand.id);
		formData.append(this.field, this.dataURItoBlob(this.croppedImage));
		if(this.originalImg) {
			formData.append(this.field + "_original", this.dataURItoBlob(this.originalImg));
		}
		this.brandService.uploadImage(formData).subscribe((event) => {
			if(event.type == HttpEventType.Response){
				if(event.body.status == 'success'){
					this.processing = false;
					this.dialogRef.close(event.body.brand)
				}
			}
		});
	}

	close(){
		this.dialogRef.close(false);
	}

	dataURItoBlob(dataURI: any) {
		// convert base64/URLEncoded data component to raw binary data held in a string
		var byteString;
		if (dataURI.split(',')[0].indexOf('base64') >= 0)
			byteString = atob(dataURI.split(',')[1]);
		else
			byteString = unescape(dataURI.split(',')[1]);

		// separate out the mime component
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to a typed array
		var ia = new Uint8Array(byteString.length);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new File([ia], "image." + (mimeString === 'image/png' ? 'png' : 'jpg'),{type:mimeString});
	}
}
