import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {BrandsService} from "../../services/brands.service";
import {Show} from "../../interfaces/show.interface";
import {Brand} from "../../interfaces/brand.interface";

@Component({
  selector: 'app-add-brand',
  templateUrl: './add-brand.component.html',
  styleUrls: ['./add-brand.component.scss']
})
export class AddBrandComponent implements OnInit {
	processing = false;
	brandForm: any;
  constructor(private dialogRef: MatDialogRef<AddBrandComponent>,
							private fb: UntypedFormBuilder,
							public brandService: BrandsService) {
		this.brandForm = this.fb.group({
			name: ['', Validators.required],
		});
	}

  ngOnInit(): void {
  }

	save(){
		if(!this.processing && this.brandForm.valid){
			const brand = this.brandForm.value as Brand;
			this.processing = true;
			this.brandService.store(brand).subscribe((response:any) => {
				this.processing = false;
				if(response.status == 'success'){
					this.dialogRef.close(response.brand);
				}
			});
		}
	}

}
