import { Show } from 'src/app/interfaces/show.interface';
import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {finalize, tap} from "rxjs/operators";
import { HttpClient, HttpEventType } from "@angular/common/http";
import {Subscription} from "rxjs";
import {ShowsService} from "../../services/shows.service";

@Component({
  selector: 'app-video-upload-popup',
  templateUrl: './video-upload-popup.component.html',
  styleUrls: ['./video-upload-popup.component.scss']
})
export class VideoUploadPopupComponent implements OnInit {

	public title = '';
	public show!: Show;
	video_url: any;
	@ViewChild('fileUpload')
	fileUpload: ElementRef | undefined;
	requiredFileType = 'video/mp4';

	fileName = '';
	uploadProgress:number|null = null;
	uploadSub: Subscription|null = null;

	constructor(private dialogRef: MatDialogRef<VideoUploadPopupComponent>,
							@Inject(MAT_DIALOG_DATA) data:any,
							private http: HttpClient,
							public showsService: ShowsService) {
		this.title = data.title;
		this.show = data.show ?? '';
	}

	onFileSelected(event:any) {
		const file:File = event.target.files[0];

		if (file) {
			this.fileName = file.name;
			const formData = new FormData();
			formData.append("video_url", file);
			formData.append("filename", this.fileName);
			formData.append("source", this.show.platform ?? '');
			const upload$ = this.showsService.uploadVideo(this.show, file, this.show.platform, this.fileName)
				.pipe(
					finalize(() => {
						this.reset()
					}),
				);

			this.uploadSub = upload$.subscribe(event => {
				if (event.type == HttpEventType.UploadProgress) {
					this.uploadProgress = event.total ? Math.round(100 * (event.loaded / event.total)) : 0;
				}
				if(event.type == HttpEventType.Response){
					if(event.body.status == 'success'){
						this.dialogRef.close({video_url: event.body.video_url, video_filename: this.fileName});
					}
				}
			})
		}
	}

	cancelUpload() {
		this.uploadSub?.unsubscribe();
		this.reset();
	}

	reset() {
		this.uploadProgress = null;
		this.uploadSub = null;
		if(this.fileUpload){
			this.fileUpload.nativeElement.value = "";
			this.fileName = '';
		}
	}

  	ngOnInit(): void {

	}

	close() {
		this.dialogRef.close();
	}

}
