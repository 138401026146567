import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {environment} from "../../environments/environment";
@Injectable()
export class MessagingService {
	currentMessage = new BehaviorSubject<any>(null);
	constructor() {
	}
	requestPermission() {
		const messaging = getMessaging();
		getToken(messaging,
			{ vapidKey: environment.firebase.vapidKey}).then(
			(currentToken) => {
				if (currentToken) {
					console.log("Hurraaa!!! we got the token.....");
					console.log(currentToken);
				} else {
					console.log('No registration token available. Request permission to generate one.');
				}
			}).catch((err) => {
			console.log('An error occurred while retrieving token. ', err);
		});
	}
	listen() {
		const messaging = getMessaging();
		onMessage(messaging, (payload) => {
			console.log('Message received. ', payload);
			this.currentMessage.next(payload);
		});
	}
}
