import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {finalize, tap} from "rxjs/operators";
import { HttpClient, HttpEventType } from "@angular/common/http";
import {Subscription} from "rxjs";
import {ShowsService} from "../../services/shows.service";

@Component({
  selector: 'app-file-upload-popup',
  templateUrl: './file-upload-popup.component.html',
  styleUrls: ['./file-upload-popup.component.scss']
})
export class FileUploadPopupComponent implements OnInit {
	public title = '';
	public value = {};
	@ViewChild('fileUpload')
	fileUpload: ElementRef | undefined;
	requiredFileType = 'audio/mpeg';

	fileName = '';
	uploadProgress:number|null = null;
	uploadSub: Subscription|null = null;

	constructor(private dialogRef: MatDialogRef<FileUploadPopupComponent>,
							@Inject(MAT_DIALOG_DATA) data:any,
							private http: HttpClient,
							public showsService: ShowsService) {
		this.title = data.title;
	}

	onFileSelected(event:any) {
		const file:File = event.target.files[0];

		if (file) {
			this.fileName = file.name;
			const formData = new FormData();
			formData.append("", file);
			formData.append("source", 'AUDIO_APP');

			const upload$ = this.showsService.uploadAudio(formData)
				.pipe(
					finalize(() => {
						this.reset()
					}),
				);

			this.uploadSub = upload$.subscribe(event => {
				if (event.type == HttpEventType.UploadProgress) {
					this.uploadProgress = event.total ? Math.round(100 * (event.loaded / event.total)) : 0;
				}
				if(event.type == HttpEventType.Response){
					if(event.body.status == 'success'){
						this.value = {
							audio_file_id: event.body.uploaded_file_info[0].file_id,
							audio_file_name: event.body.uploaded_file_info[0].file_name
						};
						this.save();
					}
				}
			})
		}
	}

	cancelUpload() {
		this.uploadSub?.unsubscribe();
		this.reset();
	}

	reset() {
		this.uploadProgress = null;
		this.uploadSub = null;
		if(this.fileUpload){
			this.fileUpload.nativeElement.value = "";
			this.fileName = '';
		}
	}

  ngOnInit(): void {
  }
	save() {
		this.dialogRef.close(this.value);
	}

	close() {
		this.dialogRef.close('cancelDialog');
	}
}
