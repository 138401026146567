<h2 class="popup-heading" mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
	<input type="file" class="file-input"
				 [accept]="requiredFileType"
				 (change)="onFileSelected($event)" #fileUpload style="display: none">

	<div class="file-upload">
		<button mat-mini-fab [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" class="upload-btn"
						(click)="fileUpload.click()">
			<mat-icon>attach_file</mat-icon>

		</button>
		{{fileName || "Browse and select MP3 file..."}}

	</div>

	<div class="progress">

		<mat-progress-bar class="progress-bar" mode="determinate"
											[value]="uploadProgress" *ngIf="uploadProgress">
		</mat-progress-bar>
		<button *ngIf="uploadProgress" mat-mini-fab [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" class="cancel-upload"
						(click)="cancelUpload()">
			<mat-icon>delete_forever</mat-icon>
		</button>

	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="cancel-popup" mat-stroked-button (click) = "close()">Cancel</button>
</mat-dialog-actions>
