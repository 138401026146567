import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Show} from "../../interfaces/show.interface";

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent implements OnInit {
	show: Show;
	qrcodeUrl = ''
	constructor(private dialogRef: MatDialogRef<QrcodeComponent>,
							@Inject(MAT_DIALOG_DATA) data:any) {
		this.show = data.show;
		if(this.show.show_code) {
			this.qrcodeUrl = 'https://qrcode.tec-it.com/API/QRCode?data=' + encodeURIComponent(this.show.show_code) + '&backcolor=%23ffffff&method=download';
		}
	}

	ngOnInit(): void {
	}

	cancel() {
		this.dialogRef.close(false);
	}

}
