import {Component, ElementRef, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { BrandsService } from 'src/app/services/brands.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Font } from 'ngx-font-picker';
import { MatMenuTrigger } from '@angular/material/menu';
import { Color } from '@angular-material-components/color-picker';
import { MatSnackBar } from '@angular/material/snack-bar';
import {BannerEditorComponent} from "./banner-editor/banner-editor.component";

@Component({
  selector: 'app-edit-brand',
  templateUrl: './edit-brand.component.html',
  styleUrls: ['./edit-brand.component.scss'],
})
export class EditBrandComponent implements OnInit {
	onUpdate = new EventEmitter();
  brand: any;
  colorsForm: any;
  processing;
  load: any;

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @ViewChild('font_family') font_family!: ElementRef;

  public color: ThemePalette = 'primary';
  public touchUi = true;

  public font: any = new Font();

  constructor(private dialogRef: MatDialogRef<EditBrandComponent>,
		@Inject(MAT_DIALOG_DATA) data: any, private fb: UntypedFormBuilder,
    private brandsService: BrandsService, public _snackBar: MatSnackBar,
							public dialog: MatDialog) {

      this.brand = data.brand;
      if(this.brand.header_image){
      	this.brand.header_image = this.brand.header_image + "?v=" + Math.random();
			}
			if(this.brand.footer_image){
				this.brand.footer_image = this.brand.footer_image + "?v=" + Math.random();
			}
      this.font.family = this.brand.font_family;
		this.font.family = 'Roboto';

      this.colorsForm = this.fb.group({
        heading_color: [this.brand.heading_color ?? ''],
        background_color: [this.brand.background_color ?? ''],
        text_color: [this.brand.text_color ?? ''],
				button_text_color: [this.brand.button_text_color ?? ''],
        button_color: [this.brand.button_color ?? ''],
      });
      this.processing = false;
  }

  ngOnInit(): void {
    if(this.brand.heading_color){
      const rgb = this.hexToRgb(this.brand.heading_color);
      if(rgb){
				this.colorsForm.get('heading_color').setValue(new Color(rgb.r, rgb.g, rgb.b));
			}
    }
		if(this.brand.background_color){
			const rgb = this.hexToRgb(this.brand.background_color);
			if(rgb){
				this.colorsForm.get('background_color').setValue(new Color(rgb.r, rgb.g, rgb.b));
			}
		}
		if(this.brand.text_color){
			const rgb = this.hexToRgb(this.brand.text_color);
			if(rgb){
				this.colorsForm.get('text_color').setValue(new Color(rgb.r, rgb.g, rgb.b));
			}
		}
		if(this.brand.button_text_color){
			const rgb = this.hexToRgb(this.brand.button_text_color);
			if(rgb){
				this.colorsForm.get('button_text_color').setValue(new Color(rgb.r, rgb.g, rgb.b));
			}
		}
		if(this.brand.button_color){
			const rgb = this.hexToRgb(this.brand.button_color);
			if(rgb){
				this.colorsForm.get('button_color').setValue(new Color(rgb.r, rgb.g, rgb.b));
			}
		}
  }

  close(){
    this.trigger.closeMenu();
  }

  save(imageField: any){
    // if(imageField == 'headerImage'){
    //   this.brand.header_image = this.headerImg;
    // }
    // if(imageField == 'footerImage'){
    //   this.brand.footer_image = this.footerImg;
    // }
    // console.log(this.brand);
    this.brandsService.edit(this.brand)
				.subscribe((res: any) => {
					if(res.status){ this._snackBar.open('Record save successfully.', 'Dismiss'); }
				});
    // this.dialogRef.close(this.brand);
  }

  submitColorForm(event: any) {
		event.preventDefault();

    const formValues = Object.keys(this.colorsForm.value).some(k => !!this.colorsForm.value[k]);
    if((!formValues || !this.colorsForm.dirty) && (!this.font_family.nativeElement.value)) return;

		this.load = true;
		const brandColors = this.colorsForm.value;
		if(brandColors.heading_color) brandColors.heading_color = "#" + brandColors.heading_color.hex;
		if(brandColors.background_color) brandColors.background_color = "#" + brandColors.background_color.hex;
		if(brandColors.text_color) brandColors.text_color = "#" + brandColors.text_color.hex;
		if(brandColors.button_text_color) brandColors.button_text_color = "#" + brandColors.button_text_color.hex;
		if(brandColors.button_color) brandColors.button_color = "#" + brandColors.button_color.hex;
    brandColors.id                  = this.brand.id;
		if(this.font_family) brandColors.font_family         = this.font_family.nativeElement.value;

		this.brandsService.edit(brandColors).subscribe((res: any) => {
      this.load = false;
      if(res.status){
      	this.brand = res.brand;
        this.trigger.closeMenu();
        this._snackBar.open('Branding saved successfully.', 'Dismiss');
        setTimeout(() => {
					this._snackBar.dismiss();
				}, 3000);
				this.onUpdate.emit(true);
      }
		});
	}

	editImage(field: string){
		const dialogRef = this.dialog.open(BannerEditorComponent,{
			id: 'edit-header',
			maxWidth: '600px',
			width: '100%',
			data: {
				brand: this.brand,
				field: field
			}
		});
		dialogRef.afterClosed().subscribe((brand: any) => {
			if(brand && brand.id) {
				this.brand = brand;
				this.brand.header_image = this.brand.header_image ? this.brand.header_image + "?v=" + Math.random() : '';
				this.brand.footer_image = this.brand.footer_image ? this.brand.footer_image + "?v=" + Math.random() : '';
				this.onUpdate.emit(true);
			}
		})
	}

	hexToRgb(hex: string) {
		const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
		hex = hex.replace(shorthandRegex, (m, r, g, b) => {
			return r + r + g + g + b + b;
		});
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}

	invertColor(hex:any) {
  	if(!hex) return '#000000';
  	if (hex.indexOf('#') === 0) {
			hex = hex.slice(1);
		}
		// convert 3-digit hex to 6-digits.
		if (hex.length === 3) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		if (hex.length !== 6) {
			throw new Error('Invalid HEX color.');
		}
		let r = parseInt(hex.slice(0, 2), 16),
			g = parseInt(hex.slice(2, 4), 16),
			b = parseInt(hex.slice(4, 6), 16);
		return (r * 0.299 + g * 0.587 + b * 0.114) > 186
			? '#000000'
			: '#FFFFFF';
	}

	versionedImage(url: string){
  	return url;// + "?v=" + Math.random();
	}
}
