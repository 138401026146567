import { Component, OnInit } from '@angular/core';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent implements OnInit {
  isPyrocast = true;
  domain = '';
  googlePlayLink = '';
  appStoreLink = '';
  isTune2 = false;
  constructor() {
    if(window.location.hostname == environment.skyDomain){
      this.isPyrocast = false;
      this.domain = 'app.skystreamevents.com';
      this.googlePlayLink = 'https://play.google.com/store/apps/details?id=com.sky.stream';
      this.appStoreLink = 'https://apps.apple.com/us/app/skystream-events/id6443556051';
    }
    else if(window.location.hostname == environment.tune2Domain){
      this.isPyrocast = false;
      this.domain = 'admin.tune2.app';
      this.isTune2 = true;
      this.googlePlayLink = 'https://play.google.com/store/apps/details?id=com.tune.two';
      this.appStoreLink = 'https://apps.apple.com/pk/app/tune2/id6547844420';
    }
    else{
      this.domain = 'app.pyrocast.com';
      this.googlePlayLink = 'https://play.google.com/store/apps/details?id=com.cobra.audioApp';
      this.appStoreLink = 'https://apps.apple.com/us/app/pyrocast/id1583221898';
    }
  }

  ngOnInit(): void {
  }

}
