<mat-dialog-content class="mat-typography">
	<div>
		<div *ngIf="!processing">
			<p *ngIf="appLink">Please use this URL or QR code to link a user directly to the app. By installing the app from this link, the user is brought directly into the show and is not require to enter the 4-digit show code. <br> <span class="link">{{ appLink }}</span><img [src]="this.qrcodeUrl" style="width: 120px; display: block; margin:0 auto;" /></p>
			<p *ngIf="!appLink">Error generating app download url. Close the window and then try again.</p>
		</div>
		<div *ngIf="processing">
				Please wait...
				<mat-progress-bar mode="indeterminate"></mat-progress-bar>
		</div>
	</div>
</mat-dialog-content>
<br>
<mat-dialog-actions align="end">
	<button class="cancel-popup" mat-stroked-button (click)="cancel()">Close</button>
</mat-dialog-actions>
