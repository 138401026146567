import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent implements OnInit {
  onConfirm = new EventEmitter();
  onCancel = new EventEmitter();
  public processing;
	public title = '';
	public message = '';
	responseError = new EventEmitter();
  errors:any;

  constructor(private dialogRef: MatDialogRef<ConfirmPopupComponent>,
							@Inject(MAT_DIALOG_DATA) data:any ) {
    this.processing = false;
		this.title = data.title;
		this.message = data.message;
  }

  ngOnInit(): void {
    this.responseError.subscribe(result=> {
        this.errors = [result];
        this.processing = false;
    });
  }

  ngOnChange(): void {
  }

	cancel() {
		this.dialogRef.close(false);
    this.onCancel.emit(false);
	}

  confirm() {
    this.processing = true;
    this.onConfirm.emit(true);
  }

}
