<h2 class="popup-heading" mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
	<mat-form-field class="example-full-width">
		<mat-label>Enter Text Here</mat-label>
		<textarea matInput [(ngModel)]="text" placeholder=""></textarea>
	  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button class="cancel-popup" mat-stroked-button (click) = "close()">Close</button>
	<button mat-stroked-button (click) = "repush()" [disabled]="processing">
		<mat-icon *ngIf="processing">
			<mat-spinner class="accent-spinner" diameter="20"></mat-spinner>
		</mat-icon>
		Repush to Firebase</button>
	<button mat-stroked-button (click) = "repush_cloudfront()" [disabled]="processing2">
			<mat-icon *ngIf="processing2">
				<mat-spinner class="accent-spinner" diameter="20"></mat-spinner>
			</mat-icon>
			Repush to Cloudfront</button>
</mat-dialog-actions>
