import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Brand } from 'src/app/interfaces/brand.interface';
import { Show } from 'src/app/interfaces/show.interface';
import { BrandsService } from 'src/app/services/brands.service';
import {DatePipe} from "@angular/common";
import { MatSnackBar } from '@angular/material/snack-bar';
import {ShowsService} from "../../services/shows.service";
import moment from "moment-timezone";
import { MatCheckboxChange } from '@angular/material/checkbox';
import {environment} from "../../../environments/environment";
import { DatabaseService } from 'src/app/services/database.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
	selector: 'app-field-editor-popup',
	templateUrl: './field-editor-popup.component.html',
	styleUrls: ['./field-editor-popup.component.scss']
})
export class FieldEditorPopupComponent implements OnInit {
	public title = '';
	show!: Show;
	brands: Brand[] = [];
	siteName = 'PYROCAST'
	isPyrocast = true
	isTune2 = false;
	website_id: number;
	processing = false;
	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '120px',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: false,
		showToolbar: false,
		placeholder: 'Terms & Conditions',
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '',
		fonts: [
			{class: 'arial', name: 'Arial'},
			{class: 'times-new-roman', name: 'Times New Roman'},
			{class: 'calibri', name: 'Calibri'},
			{class: 'comic-sans-ms', name: 'Comic Sans MS'}
		],
		customClasses: [
		{
			name: 'quote',
			class: 'quote',
		},
		{
			name: 'redText',
			class: 'redText'
		},
		{
			name: 'titleText',
			class: 'titleText',
			tag: 'h1',
		},
	],
	sanitize: true,
	toolbarPosition: 'top',
	};
	timezones:any = [];
	env: any;
	start_automatically = false;

	@ViewChild('timeInput') timeInput: ElementRef | undefined;
	hours: string[] = Array.from({ length: 24 }, (_, i) => i < 10 ? '0' + i : '' + i);
	minutes: string[] = Array.from({ length: 59 }, (_, i) => i < 10 ? '0' + i : '' + i);
	show_hours: any;
	show_minutes:any;
	constructor(private dialogRef: MatDialogRef<FieldEditorPopupComponent>,
		@Inject(MAT_DIALOG_DATA) data: any, public brandsService: BrandsService,
		public datepipe: DatePipe,
		private db: DatabaseService,private snackBar: MatSnackBar,
							public showsService: ShowsService) {

		this.brandsService.index({}, 'name', 'asc', 1, 1000).subscribe((brands: any) => {
			this.brands = brands;
		});
		this.title = data.title;
		this.show = data.value;
		if(this.show.show_id){
			this.processing = true;
			this.showsService.getSingleShow(this.show.show_id).subscribe((show: any) => {
				this.show = show;
				const [hours, minutes] = this.show.show_time ? this.show.show_time.split(":") : '';
				const hoursNumber = hours;
				const minutesNumber = minutes;
				console.log(hours);
				console.log(minutes);
				this.show_hours = hoursNumber;
				this.show_minutes = minutesNumber;
				this.start_automatically = this.show.start_automatically;
				if(this.show.created_date){
					this.show.created_date_ts = new Date(this.show.created_date.split(' ')[0] + "T" +this.show.created_date.split(' ')[1] + "+00:00");
				}
				if(this.show.show_date){
					this.show.show_date = new Date(this.show.show_date.split(' ')[0] + "T" +this.show.show_date.split(' ')[1] + "+00:00");
					this.show.show_date_ts = this.show.show_date;
					//this.show.show_date_ts = new Date(this.show.show_date.split(' ')[0] + "T" +this.show.show_date.split(' ')[1] + "+00:00");
				}
				this.show.status = '--';
				this.show.hidden = show.hidden;
				this.show.platform = show.platform;
				if(!this.show.show_timezone){
					const timezoneOffset = new Date().getTimezoneOffset();
					const sign = timezoneOffset >= 0 ? "+" : "-";
					const hours = Math.floor(Math.abs(timezoneOffset / 60));
					const minutes = Math.abs(timezoneOffset % 60);
					this.show.show_timezone = `${sign}${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
					// this.show.show_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
				}
				if(this.show.show_date){
					this.show.show_date = new Date(this.show.show_date);
				}
				// if(!this.show.user_timezone){
				// 	this.show.user_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
				// }
				this.show.user_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
				this.processing = false;
			});
		}
		if(!this.show.show_timezone){
			const timezoneOffset = new Date().getTimezoneOffset();
			const sign = timezoneOffset >= 0 ? "+" : "-";
			const hours = Math.floor(Math.abs(timezoneOffset / 60));
			const minutes = Math.abs(timezoneOffset % 60);
			this.show.show_timezone = `${sign}${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
			//this.show.show_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		}
		if(this.show.show_date){
			this.show.show_date = new Date(this.show.show_date);
		}
		const staticTimezones = [
			{ name: "Baker Island Time", offset: "-12:00" },
			{ name: "Niue Time, Samoa Standard Time", offset: "-11:00" },
			{ name: "Hawaii-Aleutian Standard Time", offset: "-10:00" },
			{ name: "Alaska Standard Time", offset: "-09:00" },
			{ name: "Pacific Standard Time", offset: "-08:00" },
			{ name: "Mountain Standard Time", offset: "-07:00" },
			{ name: "Central Standard Time", offset: "-06:00" },
			{ name: "Eastern Standard Time", offset: "-05:00" },
			{ name: "Atlantic Standard Time", offset: "-04:00" },
			{ name: "Argentina Time, Brazil Time", offset: "-03:00" },
			{ name: "South Georgia Time", offset: "-02:00" },
			{ name: "Azores Standard Time", offset: "-01:00" },
			{ name: "Greenwich Mean Time", offset: "+00:00" },
			{ name: "Central European Time, West Africa Time", offset: "+01:00" },
			{ name: "Eastern European Time, Central Africa Time", offset: "+02:00" },
			{ name: "Moscow Standard Time, East Africa Time", offset: "+03:00" },
			{ name: "Gulf Standard Time", offset: "+04:00" },
			{ name: "Pakistan Standard Time, Yekaterinburg Time", offset: "+05:00" },
			{ name: "Bangladesh Standard Time, Omsk Time", offset: "+06:00" },
			{ name: "Indochina Time, Krasnoyarsk Time", offset: "+07:00" },
			{ name: "China Standard Time, Western Australia Time", offset: "+08:00" },
			{ name: "Japan Standard Time, Korea Standard Time", offset: "+09:00" },
			{ name: "Australian Eastern Standard Time, Vladivostok Time", offset: "+10:00" },
			{ name: "Solomon Islands Time", offset: "+11:00" },
			{ name: "Fiji Time, Kamchatka Time", offset: "+12:00" },
			{ name: "Tonga Time, Phoenix Islands Time", offset: "+13:00" },
			{ name: "Line Islands Time", offset: "+14:00" }
		];
		
		for(let timezone of staticTimezones){
			if(timezone.name === 'Europe/Kyiv') continue;
			this.timezones.push({
				name: timezone.name,
				diff: timezone.offset,
				// diff_seconds: this.getOffsetFromString(timezone.offset)
			});
		}
		this.env = environment;
		if(window.location.hostname == environment.skyDomain){
			this.siteName = 'Skystream';
			this.isPyrocast = false;
		}
		else if(window.location.hostname == environment.tune2Domain){
			this.siteName = 'Tune2';
			this.isPyrocast = false;
			this.isTune2 = true;
		}
		this.website_id = this.isTune2 ? 2 : (this.isPyrocast ? 0 : 1)
	}

	getOffsetFromString(offset: string): number {
		const [hours, minutes] = offset.split(':').map(Number);
		const totalMinutes = hours * 60 + (minutes || 0);
		return totalMinutes;
		
	}

	ngOnInit(): void {
	}

	dateChanged(eventDate: string): Date | null {
		return !!eventDate ? new Date(eventDate) : null;
	}
	valdiateTime(){
		if(!this.show.show_time && this.timeInput){
			this.timeInput.nativeElement.value = '';
		}
	}
	onAutoStartChange(event: MatCheckboxChange) {
		this.start_automatically = event.checked;
		// No snackbar here, just updating state
	}
	save() {
		console.log(this.isTune2)
		console.log(this.isPyrocast)
		this.show.website_id = this.website_id;
		if (this.start_automatically && !this.show.show_date) {
			this.snackBar.open('A show date and time is required for shows set to start automatically. Please assign a show date and show time to continue.', 'Close', {
				duration: 3000,
			});
			return;
		}
		console.log(this.show.show_timezone);
		if(this.show.show_id && !this.processing) {
			this.processing = true;
			this.show.show_hours = this.show_hours;
			this.show.show_minutes = this.show_minutes;
			this.showsService.edit(this.show.show_id, this.show)
				.subscribe((response: any) => {
					if(response.status == 'success'){
						if(response.show.show_date && response.show.start_automatically){
							let btnPressTime = (+ new Date()) + 0;
							let seekTime = 0;
							let status = this.show.start_automatically ? 'play' : 'stop';
							let data = status + ',{timestamp},'+ seekTime +','+ this.show.show_code +','+ btnPressTime +',PyrocastWebsite: ' + this.datepipe.transform(new Date(), 'HH:mm:ss');
							if(window.location.hostname == this.env.tune2Domain){
								data = data + "," + (response.show.looping ? "true" : "false")
							}
							//const ts2 = (+ new Date());
							//const ts2 = this.show.show_date_ts ? this.show.show_date_ts.getTime() : 0;
							// let m: any = this.show.show_date.getMonth()+1;
							// if(m < 10){
							// 	m = "0"+m;
							// }
							// let d = this.show.show_date.getDate();
							// if(d < 10){
							// 	d = "0"+d;
							// }
							// const dt = new Date((this.show.show_date.getFullYear()+"-"+m+"-"+d)+"T"+(response.show.show_time)+":00.000"+this.show.show_timezone);
							if(!response.show.user_timezone || response.show.user_timezone == 'null'){
								response.show.user_timezone = 'UTC'
							}
							let dt = new Date(response.show.show_date.split(' ')[0] + "T" +response.show.show_date.split(' ')[1] + "+00:00");
							dt.setUTCMinutes(dt.getUTCMinutes() - this.getOffset(response.show.show_timezone, false))
							dt.setUTCMinutes(dt.getUTCMinutes() + this.getOffset(response.show.user_timezone, false))
							if(response.show.show_time){
								let time = response.show.show_time.split(":");
								if(time.length  > 1){
									dt.setUTCHours(dt.getUTCHours() + Number(time[0]));
									dt.setUTCMinutes(dt.getUTCMinutes() + Number(time[1]));
								}
							}
							console.log(dt);
							const ts2 = dt.getTime();
							const data2 = data.replace('{timestamp}', (ts2) + '')
							const data3 = data.replace('{timestamp}', (ts2+1) + '')
							console.log(this.show.show_time);
							console.log(response.show.show_time);
							console.log(ts2);
							this.db.setShowStatus(this.show.show_code, data2);
							this.showsService.updateShowJson(this.show, data3).subscribe(() => {
								response.show.status = 'play';
								response.show.status_time = dt;
								if(dt.getTime() > (new Date()).getTime()){
									response.show.status = 'scheduled';
								}
								this.processing = false;
								this.dialogRef.close(response.show);
							});
						}
						else{
							if(this.start_automatically && !response.show.start_automatically){
								const dt = new Date();
								const data = 'stop,'+ dt.getTime() +',0,'+ response.show.show_code + "," + dt.getTime() +',PyrocastWebsite: ' + this.datepipe.transform(new Date(), 'HH:mm:ss');
								this.db.setShowStatus(this.show.show_code, data);
								this.showsService.updateShowJson(this.show, data).subscribe(() => {
									response.show.status = 'stop';
									response.show.status_time = dt;
									this.processing = false;
									this.dialogRef.close(response.show);
								});
							}
							else{
								this.showsService.getShowJson(response.show).subscribe(data => {
									let status = data.split(",");
									if(status.length == 7){
										status.pop();
										const dt = new Date();
										status[4] = dt.getTime();
										let statusString = status.join(",") + "," + (response.show.looping ? 'true' : 'false');
										this.db.setShowStatus(this.show.show_code, statusString);
										this.showsService.updateShowJson(this.show, statusString).subscribe(() => {
											this.processing = false;
											this.dialogRef.close(response.show);
										});
									}
									else{
										this.processing = false;
										this.dialogRef.close(response.show);	
									}
								}, (error: any) => {
									this.processing = false;
									this.dialogRef.close(response.show);
								})
							}
						}
					}
				});
		}
	}
	getOffset(offsetString = '+00:00', formatted = true, date = new Date()): any {
		const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
		//const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
		//const offsetString = "+05:00";
		let tzDate;
		if(offsetString[0] === "+" || offsetString[0] === "-"){
			 const offsetHours = parseInt(offsetString.slice(1, 3));
			 const offsetMinutes = parseInt(offsetString.slice(4, 6));
			 const sign = offsetString[0] === "+" ? 1 : -1;
			 const offsetMilliseconds = (offsetHours * 60 * 60 + offsetMinutes * 60) * 1000 * sign;
   
			 tzDate = new Date(utcDate.getTime() + offsetMilliseconds);
		}
		else{
			 tzDate = new Date(date.toLocaleString('en-US', { timeZone: offsetString }));
		}
		const mins = (tzDate.getTime() - utcDate.getTime()) / 6e4;
		if(!formatted) return mins;
		const minsA = Math.abs(mins);
		const min = minsA % 60;
		const hr = (minsA-min)/60;
		let hrS = '';
		let minS = '';
		if(hr < 10){
			 hrS = "0" + hr;
		}
		else{
			 hrS = "" + hr;
		}
		if(min === 0){
			 minS = "00";
		}
		else{
			 minS = "" + min;
		}
		return (mins < 0 ? '-':'+') + hrS + ":" + minS;
   	}
	close() {
		this.dialogRef.close('cancelDialog');
	}
}
