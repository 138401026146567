<div class="content">
	<div class="my-show-table">
		<div class="btn">
			<mat-form-field class="table-search">
				<mat-label>Search linked users...</mat-label>
				<input matInput placeholder="Enter search term here" #search [(ngModel)]="s">
			</mat-form-field>
			<div class="shows-btn">
				<button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" class="add-btn" (click)="addEdit('')"><span style="line-height: 24px;margin-right: 5px;display: inline-block;">CREATE NEW LINKED USER</span> <mat-icon>add_box</mat-icon></button>
			</div>
		</div>
		<div class="mat-elevation-z8">
			<table mat-table [dataSource]="dataSource" matSort matSortActive="admin_email" matSortDirection="asc">

				<!-- Position Column -->
				<ng-container matColumnDef="admin_email">
					<th mat-header-cell *matHeaderCellDef mat-sort-header class="show-sm" width="50%"> Admin Email </th>
					<td mat-cell *matCellDef="let element" (click)="edit(element)" class="show-sm"> {{element.admin_email}} </td>
				</ng-container>

				<ng-container matColumnDef="user_emails">
					<th mat-header-cell *matHeaderCellDef mat-sort-header width="50%"> Linked Emails </th>
					<td mat-cell *matCellDef="let element"><span [innerHtml]="element.user_emails.split('\n').join('<br>')"></span></td>
				</ng-container>

				<ng-container matColumnDef="action">
					<th mat-header-cell *matHeaderCellDef class="show-sm action-col" width="7%"> Action </th>
					<td mat-cell *matCellDef="let element" class="show-sm action-col">
						<div class="table-select">
							<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Linked User Actions" [disabled]="element.processing">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item (click)="addEdit(element)">
									<span>Edit</span>
								</button>
								<button mat-menu-item (click)="delete(element.id)">
									<span>Delete</span>
								</button>
							</mat-menu>
						</div>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				<tr class="mat-row" *matNoDataRow>
					<td colspan="7" class="mat-cell show-sm">{{ loading ? 'Loading...':'No records found.'}}</td>
				</tr>
			</table>
			<mat-paginator [class]="total_linked_users <= 1 ? 'hidden_dropdown':''" [pageSizeOptions]="[10, 50, 100, 250, 500]" aria-label="Select page of the records."></mat-paginator>
		</div>
	</div>
</div>
