import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { Show } from 'src/app/interfaces/show.interface';

@Component({
  selector: 'app-video-preview-popup',
  templateUrl: './video-preview-popup.component.html',
  styleUrls: ['./video-preview-popup.component.scss']
})
export class VideoPreviewPopupComponent implements OnInit {
  public show!: Show;
	videoName = '';
  constructor(private dialogRef: MatDialogRef<VideoPreviewPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data:any
      ) {
    this.show = data.show;
		this.videoName = (data ? data.show.video_url.split("/").pop() : '');
  }

  ngOnInit(): void {
  }

  close() {
		this.dialogRef.close();
	}

}
