<h2 class="popup-heading" mat-dialog-title>Add New Notification</h2>
<form>
	<mat-dialog-content class="mat-typography">
		<div class="row">
			<div class="col-md-12">
				<mat-form-field appearance="fill">
					<mat-label>Title</mat-label>
					<input matInput placeholder="Enter Title" [formControl]="form.controls.title"
						required>
					<mat-error *ngIf="form.controls.title.hasError('required')">
						Title is <strong>required</strong>
					</mat-error>
				</mat-form-field>
				<mat-form-field appearance="fill">
					<mat-label>Platform</mat-label>
					<mat-select  [formControl]="form.controls.app">
                        <mat-option value="0">IOS</mat-option>
                        <mat-option value="1">Android</mat-option>
                        <mat-option value="2">Both</mat-option>
                    </mat-select>
                </mat-form-field>
                <angular-editor [formControl]="form.controls.url" [config]="editorConfig"></angular-editor>
                
				<mat-form-field appearance="fill" class="single-field">
					<mat-label>App Version</mat-label>
					<input matInput [formControl]="form.controls.version">
				</mat-form-field>
			</div>
		</div>
		<div>
			<mat-checkbox class="add-smpte-chkbx" [formControl]="form.controls.upgrade_message"> Upgrade Message
			</mat-checkbox>
        </div>
        <div>
			<mat-checkbox class="add-smpte-chkbx" [formControl]="form.controls.critical"> Critical
			</mat-checkbox>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button class="cancel-popup" mat-stroked-button mat-dialog-close>Cancel</button>
		<button class="popup-save" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" type="submit" (click)="save()" [disabled]="processing">
			<mat-icon *ngIf="processing">
				<mat-spinner class="accent-spinner" diameter="20"></mat-spinner>
			</mat-icon>
			Save
		</button>
	</mat-dialog-actions>
</form>
