import { Component, OnInit } from '@angular/core';
import { ShowsService } from '../services/shows.service';
import {environment} from "../../environments/environment";
import {Show} from "../interfaces/show.interface";
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-fingerprint-report',
  templateUrl: './fingerprint-report.component.html',
  styleUrls: ['./fingerprint-report.component.scss']
})
export class FingerprintReportComponent implements OnInit {
  displayedColumns: string[] = ['reporting_period', 'title', 'show_codes', 'artists', 'platform', 'isrc', 'upc', 'listeners_count', 'duration'];
  dataSource = [];
  wesbite_id: number = -1;
	show: any;
  platforms:any = [];
  start_date: any;
  end_date: any;
  loading = false;
  processing = false;
  
  constructor(public showsService: ShowsService) {
    this.start_date = new Date();
    this.start_date.setDate(this.start_date.getDate() - 30);
    this.end_date = new Date();
    const staticPlatforms = [
			{ id: 0 , title: "Pyrocast" },
			{ id: 1 , title: "Skystream" },
			{ id: 2 , title: "Tune2" }
		];
    for(let platform of staticPlatforms){
      this.platforms.push({
        id: platform.id,
        title: platform.title,
      });
    }
  }

  ngOnInit(): void {
    this.getReport();
  }

  getReport(){
    this.loading = true;
    this.dataSource = [];
    this.showsService.getFingerprints(this.start_date, this.end_date, this.wesbite_id).subscribe((response: any) => {
      if(response.status){
        this.dataSource = response.songs;
      }
      this.loading = false;
    });
  }
  

  download(row: any){
    if(!this.processing){
      this.processing = true;
      this.showsService.getFingerprintDownloadToken(row.show_code).subscribe((response: any) => {
        if(response.status){
          window.open(environment.apiUrl+'/shows/fingerprints/download/' + row.show_code + '?token=' + response.token, '_blank');
        }
        this.processing = false;
      });
    }
  }
  // downloadCsv(){
  //   if(!this.processing){
  //     this.processing = true;
  //     new AngularCsv(this.dataSource, 'My Report');
  //     // this.showsService.getFingerprintDownloadToken().subscribe((response: any) => {
  //     //   if(response.status){
  //     //     //window.open(environment.apiUrl+'/shows/fingerprints/download/' + row.show_code + '?token=' + response.token, '_blank');
  //     //   }
  //     //   this.processing = false;
  //     // });
  //   }
  // }
  downloadCsv() {
    if (!this.processing) {
      this.processing = true;
  
      // Assuming 'array' contains your data
      const array = this.dataSource;
  
      // Define the headers
      const head = ['Reporting Period','Shows','acrid', 'Song Title','Artists', 'Platform','ISRC','UPC','Time Played','Performance Count'];
  
      // Use Angular2Csv to generate and download the CSV with headers
      new AngularCsv(array, 'PRO Reporting '+formatDate(this.start_date,'shortDate', 'en')+' - '+formatDate(this.end_date,'shortDate', 'en'), { headers: head });
  
    }
  }
  
}
