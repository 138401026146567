import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Show } from "../../interfaces/show.interface";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import { HttpEventType } from "@angular/common/http";
import { ShowsService } from "../../services/shows.service";
import { MatDialogRef } from "@angular/material/dialog";
import { BrandsService } from 'src/app/services/brands.service';
import { Brand } from 'src/app/interfaces/brand.interface';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import {environment} from "../../../environments/environment";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DatabaseService } from 'src/app/services/database.service';

@Component({
	selector: 'app-add-show',
	templateUrl: './add-show.component.html',
	styleUrls: ['./add-show.component.scss']
})
export class AddShowComponent implements OnInit {
	showForm: any;
	processing = false;
	@ViewChild('fileUpload')
	fileUpload: ElementRef | undefined;
	requiredFileType = 'audio/mpeg';
	brands: Brand[] = [];
	file: any;
	fileName = '';
	uploadProgress: number | null = null;
	uploadSub: Subscription | null = null;
	timezones:any = [];
	timezonesMap: Map<string, string[]> = new Map();
	env: any;
	siteName = 'PYROCAST'
	isPyrocast = true
	isTune2 = false;
	website_id: number;
	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '120px',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: false,
		showToolbar: false,
		placeholder: 'Terms & Conditions',
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '',
		fonts: [
			{class: 'arial', name: 'Arial'},
			{class: 'times-new-roman', name: 'Times New Roman'},
			{class: 'calibri', name: 'Calibri'},
			{class: 'comic-sans-ms', name: 'Comic Sans MS'}
		],
		customClasses: [
		{
			name: 'quote',
			class: 'quote',
		},
		{
			name: 'redText',
			class: 'redText'
		},
		{
			name: 'titleText',
			class: 'titleText',
			tag: 'h1',
		},
	],
	sanitize: true,
	toolbarPosition: 'top',
	};

	
	hours: string[] = Array.from({ length: 24 }, (_, i) => i < 10 ? '0' + i : '' + i);
	minutes: string[] = Array.from({ length: 59 }, (_, i) => i < 10 ? '0' + i : '' + i);
	constructor(private dialogRef: MatDialogRef<AddShowComponent>,
		private fb: UntypedFormBuilder,
		public datepipe: DatePipe,
		public showsService: ShowsService, public brandsService: BrandsService,
		private db: DatabaseService) {

		this.brandsService.index({}, 'name', 'asc', 1, 1000).subscribe((brands: any) => {
			this.brands = brands;
		});
		const timezoneOffset = new Date().getTimezoneOffset();
		const sign = timezoneOffset >= 0 ? "+" : "-";
		const hours = Math.floor(Math.abs(timezoneOffset / 60));
		const minutes = Math.abs(timezoneOffset % 60);
		const selected_timezone = `${sign}${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
		const staticTimezones = [
			{ name: "Baker Island Time", offset: "-12:00", isDLS: false, startTime: null, endTime: null },
			{ name: "Niue Time, Samoa Standard Time", offset: "-11:00", isDLS: false, startTime: null, endTime: null },
			{ name: "Hawaii-Aleutian Standard Time", offset: "-10:00", isDLS: true, startTime: "March 13 02:00:00", endTime: "November 6 02:00:00" },
			{ name: "Alaska Standard Time", offset: "-09:00", isDLS: true, startTime: "March 13 02:00:00", endTime: "November 6 02:00:00" },
			{ name: "Pacific Standard Time", offset: "-08:00", isDLS: true, startTime: "March 13 02:00:00", endTime: "November 6 02:00:00" },
			{ name: "Mountain Standard Time", offset: "-07:00", isDLS: true, startTime: "March 13 02:00:00", endTime: "November 6 02:00:00" },
			{ name: "Central Standard Time", offset: "-06:00", isDLS: true, startTime: "March 13 02:00:00", endTime: "November 6 02:00:00" },
			{ name: "Eastern Standard Time", offset: "-05:00", isDLS: true, startTime: "March 13 02:00:00", endTime: "November 6 02:00:00" },
			{ name: "Atlantic Standard Time", offset: "-04:00", isDLS: true, startTime: "March 13 02:00:00", endTime: "November 6 02:00:00" },
			{ name: "Argentina Time, Brazil Time", offset: "-03:00", isDLS: false, startTime: null, endTime: null },
			{ name: "South Georgia Time", offset: "-02:00", isDLS: false, startTime: null, endTime: null },
			{ name: "Azores Standard Time", offset: "-01:00", isDLS: true, startTime: "March 27 01:00:00", endTime: "October 30 02:00:00" },
			{ name: "Greenwich Mean Time", offset: "+00:00", isDLS: false, startTime: null, endTime: null },
			{ name: "Central European Time, West Africa Time", offset: "+01:00", isDLS: true, startTime: "March 27 02:00:00", endTime: "October 30 03:00:00" },
			{ name: "Eastern European Time, Central Africa Time", offset: "+02:00", isDLS: true, startTime: "March 27 02:00:00", endTime: "October 30 03:00:00" },
			{ name: "Moscow Standard Time, East Africa Time", offset: "+03:00", isDLS: false, startTime: null, endTime: null },
			{ name: "Gulf Standard Time", offset: "+04:00", isDLS: false, startTime: null, endTime: null },
			{ name: "Pakistan Standard Time, Yekaterinburg Time", offset: "+05:00", isDLS: false, startTime: null, endTime: null },
			{ name: "Bangladesh Standard Time, Omsk Time", offset: "+06:00", isDLS: false, startTime: null, endTime: null },
			{ name: "Indochina Time, Krasnoyarsk Time", offset: "+07:00", isDLS: false, startTime: null, endTime: null },
			{ name: "China Standard Time, Western Australia Time", offset: "+08:00", isDLS: false, startTime: null, endTime: null },
			{ name: "Japan Standard Time, Korea Standard Time", offset: "+09:00", isDLS: false, startTime: null, endTime: null },
			{ name: "Australian Eastern Standard Time, Vladivostok Time", offset: "+10:00", isDLS: true, startTime: "October 2 02:00:00", endTime: "April 3 03:00:00" },
			{ name: "Solomon Islands Time", offset: "+11:00", isDLS: false, startTime: null, endTime: null },
			{ name: "Fiji Time, Kamchatka Time", offset: "+12:00", isDLS: true, startTime: "November 6 02:00:00", endTime: "January 16 03:00:00" },
			{ name: "Tonga Time, Phoenix Islands Time", offset: "+13:00", isDLS: false, startTime: null, endTime: null },
			{ name: "Line Islands Time", offset: "+14:00", isDLS: false, startTime: null, endTime: null }
		];
		
		for(let timezone of staticTimezones){
			if(timezone.name === 'Europe/Kyiv') continue;
			this.timezones.push({
				name: timezone.name,
				diff: timezone.offset,
				// diff_seconds: this.getOffsetFromString(timezone.offset)
			});
		}
		this.env = environment;
		this.timezones.sort((a:any, b:any) => {
			return a.diff_seconds - b.diff_seconds + (a.name.indexOf('US/') !== -1 ? -1000000000 : 0);
		});
		this.env = environment;
		if(window.location.hostname == environment.skyDomain){
			this.siteName = 'Skystream';
			this.isPyrocast = false;
		}
		else if(window.location.hostname == environment.tune2Domain){
			this.siteName = 'Tune2';
			this.isPyrocast = false;
			this.isTune2 = true;
		}
		this.website_id = this.isTune2 ? 2 : (this.isPyrocast ? 0 : 1)
		this.showForm = this.fb.group({
			custom_name: ['', Validators.required],
			address1: [''],
			address2: [''],
			city: [''],
			state: [''],
			country: [''],
			show_date: [ '' ],
			show_time: [ '' ],
			show_hours: [ '' ],
			show_minutes: [ '' ],
			show_timezone: [ selected_timezone ],
			user_timezone: [ Intl.DateTimeFormat().resolvedOptions().timeZone ],
			start_automatically: [false],
			looping: [false],
			description: [''],
			website_url: [''],
			website_id: [this.website_id],
			extra_button_text: [''],
			extra_button_url: [''],
			terms: [''],
			datetime_text: [''],
			brand_id: [0],
			add_smpte: [false],
			audio_file_id: '',
			video_before_show: [''],
			video_after_show: [''],
		});
	}
	
	getOffsetFromString(offset: string): number {
		const [hours, minutes] = offset.split(':').map(Number);
		const totalMinutes = hours * 60 + (minutes || 0);
		return totalMinutes;
		
	}
	ngOnInit(): void { }

	dateChanged(eventDate: string): Date | null {
		return !!eventDate ? new Date(eventDate) : null;
	}

	onFileSelected(event: any) {
		this.file = event.target.files[0];
		this.fileName = this.file.name;
	}
	process() {
		if (this.showForm.valid) {
			if (!this.processing && this.file) {
				this.processing = true;
				const formData = new FormData();
				formData.append("", this.file);
				formData.append("source", 'AUDIO_APP');

				const upload$ = this.showsService.uploadAudio(formData)
					.pipe(
						finalize(() => {
							this.reset()
						}),
					);

				this.uploadSub = upload$.subscribe(event => {
					if (event.type == HttpEventType.UploadProgress) {
						this.uploadProgress = event.total ? Math.round(100 * (event.loaded / event.total)) : 0;
					}
					if (event.type == HttpEventType.Response) {
						if (event.body.status == 'success') {
							this.showForm.controls['audio_file_id'].setValue(event.body.uploaded_file_info[0].file_id)
							this.processing = false;
							this.save(event.body);
						}
					}
				})
			}
			else {
				this.save(null);
			}
		}
	}

	cancelUpload() {
		this.uploadSub?.unsubscribe();
		this.reset();
	}

	reset() {
		this.processing = false;
		this.uploadProgress = null;
		this.uploadSub = null;
		if (this.fileUpload) {
			this.fileUpload.nativeElement.value = "";
		}
	}

	save(file_info: any) {
		console.log(this.isTune2)
		console.log(this.isPyrocast)
		if (!this.processing) {
			const show = this.showForm.value as Show;
			show.show_date = this.dateChanged(this.showForm.get('show_date').value);
			this.processing = true;
			this.showsService.store(show).subscribe((response: any) => {
				this.processing = false;
				if (response.status == 'success') {
					if (file_info) {
						response.show.audio_file_id = file_info.uploaded_file_info[0].file_id;
						response.show.audio_file_name = file_info.uploaded_file_info[0].file_name;
						response.show.add_smpte = show.add_smpte;
					}
					if(response.show.show_date && response.show.start_automatically){
						let btnPressTime = (+ new Date()) + 0;
						let seekTime = 0;
						let status = response.show.start_automatically ? 'play' : 'stop';
						let data = status + ',{timestamp},'+ seekTime +','+ response.show.show_code +','+ btnPressTime +',PyrocastWebsite: ' + this.datepipe.transform(new Date(), 'HH:mm:ss');
						if(window.location.hostname == this.env.tune2Domain){
							data = data + "," + (response.show.looping ? "true" : "false")
						}
						// let m: any = show.show_date.getMonth()+1;
						// if(m < 10){
						// 	m = "0"+m;
						// }
						// let d = show.show_date.getDate();
						// if(d < 10){
						// 	d = "0"+d;
						// }
						// const dt = new Date((show.show_date.getFullYear()+"-"+m+"-"+d)+"T"+(response.show.show_time)+":00.000"+this.getOffsetFromString(response.show.show_timezone));
						if(!response.show.user_timezone || response.show.user_timezone == 'null'){
							response.show.user_timezone = 'UTC'
						}
						let dt = new Date(response.show.show_date.split(' ')[0] + "T" +response.show.show_date.split(' ')[1] + "+00:00");
						dt.setUTCMinutes(dt.getUTCMinutes() - this.getOffset(response.show.show_timezone, false))
						dt.setUTCMinutes(dt.getUTCMinutes() + this.getOffset(response.show.user_timezone, false))
						if(response.show.show_time){
							let time = response.show.show_time.split(":");
							if(time.length  > 1){
								dt.setUTCHours(dt.getUTCHours() + Number(time[0]));
								dt.setUTCMinutes(dt.getUTCMinutes() + Number(time[1]));
							}
						}
						const ts2 = dt.getTime();
						const data2 = data.replace('{timestamp}', (ts2) + '')
						const data3 = data.replace('{timestamp}', (ts2+1) + '')
						console.log(response.show.show_time);
						console.log(response.show.show_time);
						console.log(ts2);
						this.db.setShowStatus(response.show.show_code, data2);
						this.showsService.updateShowJson(response.show, data3).subscribe(() => {
							response.show.status = 'play';
							response.show.status_time = dt;
							if(dt.getTime() > (new Date()).getTime()){
								response.show.status = 'scheduled';
							}
							this.processing = false;
							this.dialogRef.close(response.show);
						});
						console.log(dt)
					}
					else{
						this.dialogRef.close(response.show);
					}
					
					console.log(response.show.user_timezone)
				}
			});
		}
	}
	getOffset(offsetString = '+00:00', formatted = true, date = new Date()): any {
		const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
		//const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
		//const offsetString = "+05:00";
		let tzDate;
		if(offsetString[0] === "+" || offsetString[0] === "-"){
			 const offsetHours = parseInt(offsetString.slice(1, 3));
			 const offsetMinutes = parseInt(offsetString.slice(4, 6));
			 const sign = offsetString[0] === "+" ? 1 : -1;
			 const offsetMilliseconds = (offsetHours * 60 * 60 + offsetMinutes * 60) * 1000 * sign;
   
			 tzDate = new Date(utcDate.getTime() + offsetMilliseconds);
		}
		else{
			 tzDate = new Date(date.toLocaleString('en-US', { timeZone: offsetString }));
		}
		const mins = (tzDate.getTime() - utcDate.getTime()) / 6e4;
		if(!formatted) return mins;
		const minsA = Math.abs(mins);
		const min = minsA % 60;
		const hr = (minsA-min)/60;
		let hrS = '';
		let minS = '';
		if(hr < 10){
			 hrS = "0" + hr;
		}
		else{
			 hrS = "" + hr;
		}
		if(min === 0){
			 minS = "00";
		}
		else{
			 minS = "" + min;
		}
		return (mins < 0 ? '-':'+') + hrS + ":" + minS;
   }
	close() {
		this.dialogRef.close(false);
	}

}
