<p *ngIf="field=='header_image'">If you load an image that is smaller than the header area, the background color will appear beyond the image. If you wish to fill the full areas, we suggest image dimensions of 360 x 120px, which is a 3:1 ratio.</p>
<p *ngIf="field=='footer_image'">If you load an image that is smaller than the footer area, the background color will appear beyond the image. If you wish to fill the full areas, we suggest image dimensions of 360 x 90px, which is a 4:1 ratio.</p>
<div [lyDisplay]="'flex'" style="max-width: 400px; margin-bottom: 10px">
	<button ly-button class="mat-raised-button" [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" (click)="_fileInput.click()">
		<ly-icon>image</ly-icon>
		<span>Select Image File</span>
	</button>
	<span [lyFlex]="1"></span>
	<!-- Hidden input -->
	<input #_fileInput type="file" (change)="fileSelect($event)" accept="image/*" hidden>

	<div style="float: right">
		<div *ngIf="ready">
			<button (click)="cropper.rotate(-90)" ly-button appearance="icon"><ly-icon>rotate_90_degrees_ccw</ly-icon></button>
			<button (click)="cropper.zoomIn()" ly-button appearance="icon"><ly-icon>zoom_in</ly-icon></button>
			<button (click)="zoomOut()" ly-button appearance="icon"><ly-icon>zoom_out</ly-icon></button>
		</div>
	</div>
</div>
<div *ngIf="!imgLoaded && brand.header_image">
	Loading the Image...
</div>
<div [hidden]="!imgLoaded">
	<ly-img-cropper
		[config]="myConfig"
		[(scale)]="scale"
		(minScale)="minScale = $event"
		(maxScale)="maxScale = $event"
		(ready)="ready = true"
		(cleaned)="ready = false"
		(cropped)="onCropped($event)"
		(loaded)="onLoaded($event)"
		(error)="onError($event)"
	>
	</ly-img-cropper>
</div>
<div style="margin: 15px 0">
	<button mat-raised-button (click)="close()" class="gray-btn" style="margin-right: 15px;">
		Cancel
	</button>
	<button mat-raised-button *ngIf="ready" [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" (click)="cropper.crop()" style="margin-right: 15px;">
		<ly-icon>crop</ly-icon> Preview
	</button>
	<button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" *ngIf="ready" (click)="save()" [disabled]="processing">Save Image <mat-icon *ngIf="processing">
		<mat-spinner color="accent" diameter="20"></mat-spinner>
	</mat-icon></button>
</div>

<div>
	<img [lyMaxWidth]="1" *ngIf="croppedImage && !disablePreview" [src]="croppedImage">
</div>
