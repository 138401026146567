<div class="content">
	<div class="my-show-table">
        <div class="finger-head">
                <mat-form-field>
                    <mat-label>From Date</mat-label>
                    <input matInput [matDatepicker]="picker1" [(ngModel)]="start_date">
                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                  
                  <mat-form-field>
                    <mat-label>To Date</mat-label>
                    <input matInput [matDatepicker]="picker2" [(ngModel)]="end_date">
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 color="primary"></mat-datepicker>
                  </mat-form-field>
                  
                  <mat-form-field>
                    <mat-label>Choose Platform</mat-label>
                    <mat-select disableRipple [(value)]="wesbite_id">
                      <mat-option [value]="-1">All Platforms</mat-option>
                      <mat-option *ngFor="let platform of platforms" [value]="platform.id">{{ platform.title }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  
                <button mat-raised-button [ngStyle]="{'background-color': 'var(--accent-color)', 'color': 'var(--light-color)'}" class="add-btn" (click)="getReport()"><span style="line-height: 24px;margin-right: 5px;display: inline-block;">Submit</span></button>
                <button style="float: right" mat-raised-button [ngStyle]="{'background-color': 'var(--accent-color)', 'color': 'var(--light-color)'}" class="add-btn" (click)="downloadCsv()"><span style="line-height: 24px;margin-right: 5px;display: inline-block;">Download CSV</span></button>
        </div>
		<div class="mat-elevation-z8 ex">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="reporting_period">
                  <th mat-header-cell *matHeaderCellDef> Reporting Period </th>
                  <td mat-cell *matCellDef="let element"> {{element.reporting_period}} </td>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef> Song Title </th>
                  <td mat-cell *matCellDef="let element"> {{element.title}} </td>
                </ng-container>
                
                <!-- Name Column -->
                <ng-container matColumnDef="show_codes">
                  <th mat-header-cell *matHeaderCellDef> Shows </th>
                  <td mat-cell *matCellDef="let element"> {{element.show_codes.join(", ")}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="artists">
                  <th mat-header-cell *matHeaderCellDef> Artists </th>
                  <td mat-cell *matCellDef="let element"> {{element.artists}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="platform">
                  <th mat-header-cell *matHeaderCellDef> Platform </th>
                  <td mat-cell *matCellDef="let element"> {{element.platform}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="isrc">
                  <th mat-header-cell *matHeaderCellDef> ISRC </th>
                  <td mat-cell *matCellDef="let element"> {{element.isrc}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="upc">
                  <th mat-header-cell *matHeaderCellDef> UPC </th>
                  <td mat-cell *matCellDef="let element"> {{element.upc}} </td>
                </ng-container>
                
                <!-- Name Column -->
                <ng-container matColumnDef="listeners_count">
                  <th mat-header-cell *matHeaderCellDef> Performance Count </th>
                  <td mat-cell *matCellDef="let element"> {{element.listeners_count}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="duration">
                  <th mat-header-cell *matHeaderCellDef> Time Played </th>
                  <td mat-cell *matCellDef="let element"> {{element.duration}}s </td>
                </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef class="show-sm action-col" width="7%"> Action </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table-select">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button (click)="download(element)" mat-menu-item>
                                <span>Download</span>
                            </button>
                        </mat-menu>
                    </div>
                  </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td colspan="8" class="mat-cell">{{ loading ? 'Loading...':'No records found.'}}</td>
                </tr>
              </table>
		</div>
	</div>
</div>