import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-inactive-popup',
  templateUrl: './inactive-popup.component.html',
  styleUrls: ['./inactive-popup.component.scss']
})
export class InactivePopupComponent implements OnInit {
  onConfirm = new EventEmitter();
  public title = '';
	public message = '';
	public time = 60;

  constructor(private dialogRef: MatDialogRef<InactivePopupComponent>,
							@Inject(MAT_DIALOG_DATA) data:any ) {
    this.title = data.title;
		this.message = data.message;
		this.timer();
  }

	timer(){
		setTimeout(() => {
			this.time--;
			if(this.time === 0){
				this.onConfirm.emit(true);
			}
			this.timer();
		}, 1000);
	}

  ngOnInit(): void {
  }

  ngOnChange(): void {
  }

	cancel() {
		this.onConfirm.emit(true);
	}

  confirm() {
		this.dialogRef.close(false);
  }

}
