import { LinkedUsersService } from './../../services/linked-users.service';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {LinkedUser} from "../../interfaces/linked-user.interface";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-add-edit-linked-users',
  templateUrl: './add-edit-linked-users.component.html',
  styleUrls: ['./add-edit-linked-users.component.scss']
})
export class AddEditLinkedUsersComponent implements OnInit {

	processing = false;
	linkedUserForm: any;
	linkedUser: any;
	constructor(private dialogRef: MatDialogRef<AddEditLinkedUsersComponent>,
							private fb: UntypedFormBuilder,
							public linkedUsersService: LinkedUsersService,
							@Inject(MAT_DIALOG_DATA) public data: any,
							public _snackBar: MatSnackBar) {
							this.linkedUserForm = this.fb.group(this.addEditForm(data.linked_user));
	}

	addEditForm(data: any) {
		return {
			admin_email: [data.admin_email ?? '', Validators.required],
			user_emails: [data.user_emails ?? '', Validators.required],
		};
	}

	ngOnInit(): void {	}

	save(){
		if(!this.processing && this.linkedUserForm.valid){
			const linkedUser = this.linkedUserForm.value as LinkedUser;
			if (this.data.linked_user) linkedUser.id = this.data.linked_user.id;
			this.processing = true;
			this.linkedUsersService.storeOrEdit(linkedUser)
				.subscribe((res: any) => {
					if(res.status){ this.processing = false; this.dialogRef.close(res.linkedUser); this._snackBar.open('Linked User updated successfully.', 'Dismiss'); }
				});
		}
	}

}
