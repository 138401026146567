import { VideoUploadPopupComponent } from './../common/video-upload-popup/video-upload-popup.component';
import {AfterViewInit, Component, ViewChild, OnInit, ElementRef, ChangeDetectorRef, EventEmitter} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {ShowsService} from "../services/shows.service";
import {MatSort} from "@angular/material/sort";
import {fromEvent} from "rxjs";
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";
import {FieldEditorPopupComponent} from "../common/field-editor-popup/field-editor-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {Show} from "../interfaces/show.interface";
import {FileUploadPopupComponent} from "../common/file-upload-popup/file-upload-popup.component";
import {MatTableDataSource} from "@angular/material/table";
import { AddShowComponent } from './add-show/add-show.component';
import {AudioPreviewPopupComponent} from "../common/audio-preview-popup/audio-preview-popup.component";
import {VideoPreviewPopupComponent} from "../common/video-preview-popup/video-preview-popup.component";
import {AuthService} from "../services/auth/auth.service";
import {environment} from "../../environments/environment";
import {ConfirmPopupComponent} from "../common/confirm-popup/confirm-popup.component";
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {LoadingComponent} from "../common/loading/loading.component";
import {AlertPopupComponent} from "../common/alert-popup/alert-popup.component";
import {MySingleShowComponent} from "../common/my-single-show/my-single-show.component";
import {EditBrandComponent} from "../brands/edit-brand/edit-brand.component";
import {PreviewBrandComponent} from "./preview-brand/preview-brand.component";
import {AnnouncementsComponent} from "../common/announcements/announcements.component";
import {QrcodeComponent} from "../common/qrcode/qrcode.component";
import {AppLinkComponent} from "../common/app-link/app-link.component";
import { MatSnackBar } from '@angular/material/snack-bar';
import { SecurityCodeComponent } from '../common/security-code/security-code.component';
import { ShowReportingComponent } from '../common/show-reporting/show-reporting.component';
import { HttpErrorResponse } from '@angular/common/http';
import { RepushPopupComponent } from '../common/repush-popup/repush-popup.component';


@Component({
  selector: 'app-my-show',
  templateUrl: './my-show.component.html',
  styleUrls: ['./my-show.component.scss']
})
export class MyShowComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['custom_name', 'show_code', 'brand_name', 'platform', 'audio_file_name', 'status', 'timecode_channel', 'action'];
	dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
	s: string = '';
	hidden = "0";
	total_shows = 0;
	status = [];
	loading = false;
	onProcessedSMPTE = new EventEmitter();
	retried = 0;
	env: any;
	siteName = 'Pyrocast'
	isPyrocast = true
	isTune2 = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort)sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

  ngAfterViewInit() {
	if(window.location.hostname == environment.skyDomain){
		this.siteName = 'Skystream';
		this.isPyrocast = false;
	}
	else if(window.location.hostname == environment.tune2Domain){
		this.siteName = 'Tune2';
		this.isPyrocast = false;
		this.isTune2 = true;
	}
		this.paginator._intl.itemsPerPageLabel="Shows per page:";
		this.dataSource.sort = this.sort;
		this.dataSource.paginator = this.paginator;
		fromEvent(this.search.nativeElement,'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;
					this.updateFilters();
				})
			)
			.subscribe();
  }
	updateHiddenFilter(event: MatButtonToggleChange) {
		this.hidden = event.value;
		this.updateFilters();
		this.total_shows = this.dataSource.filteredData.length;
	}

	updateFilters() {
		this.dataSource.filter = JSON.stringify({ s: this.s, hidden: this.hidden });
	}

  constructor(public showsService: ShowsService,
							public auth: AuthService,
							public dialog: MatDialog,
							private cdr: ChangeDetectorRef,
							private _snackBar: MatSnackBar) {
  	this.loadData();
  	this.env = environment;
	}
	showsFilter() {
		let filterFunction = function (data: any, filter: string): boolean {
			let filters = JSON.parse(filter);
			let match = false;
			if(filters.hidden !== '-1'){
				let hidden = (filters.hidden==="1")
				match = (data.hidden === hidden);
			}
			else{
				match = true;
			}
			return match && ( data.audio_file_id && data.audio_file_id.toString().toLowerCase().trim().indexOf(filters.s.toLowerCase()) !== -1 ||
				data.audio_file_name && data.audio_file_name.toString().toLowerCase().trim().indexOf(filters.s.toLowerCase()) !== -1 ||
				data.custom_name && data.custom_name.toString().toLowerCase().trim().indexOf(filters.s.toLowerCase()) !== -1 ||
				data.show_code && data.show_code.toString().toLowerCase().trim().indexOf(filters.s.toLowerCase()) !== -1 ||
				data.show_id && data.show_id.toString().toLowerCase().trim().indexOf(filters.s.toLowerCase()) !== -1
			);
		}
		return filterFunction
	}
	loadData(newShow:any = null) {
		this.loading = true;
		this.dataSource.data = [];
		this.showsService.getReport('1LNU').subscribe(data => {});
		this.showsService.index({}).subscribe(data => {
			this.loading = false;
			data.map( (show: Show) => {
				this.showsService.getShowJson(show).subscribe(data => {
					show.status = data.split(",")[0];
					show.status_time = new Date(Number(data.split(",")[1]));
					if(show.status_time.getTime() > (new Date()).getTime()){
						show.status = 'scheduled';
					}
				})
				/*if(show.audio_file_id){
					this.showsService.getFileInfo(show.audio_file_id, show).subscribe((data:any) => {
						show.timecode_channel = data.timecode_channel;
						show.file_url = show.timecode_channel === 'right' ? data.timecode_file_url : data.file_url
					})
				}
				else{
					show.timecode_channel = 'none';
				}*/
				show.status = '--';
				if(show.video_url && !show.video_filename){
					show.video_filename = show.video_url.split("/").pop();
				}
				if(show.created_date){
					show.created_date_ts = new Date(show.created_date.split(' ')[0] + "T" +show.created_date.split(' ')[1] + "+00:00");
				}
				if(show.show_date){
					show.show_date_ts = new Date(show.show_date.split(' ')[0] + "T" +show.show_date.split(' ')[1] + "+00:00");
				}
				if(newShow && newShow.audio_file_id && newShow.show_id == show.show_id){
					show.audio_file_name = newShow.audio_file_name.replace('.mp3', '')
					this.showDetails(show);
				}
				return show;
			})
			this.dataSource.data = data;
			this.updateFilters();
			this.total_shows = this.dataSource.filteredData.length
		}, error => {
			if(error.error.error.code){
				this.auth.logout().then(status => {
					window.location.href = environment.appUrl;
				});
			}
		})
	}

	addShow() {
		const dialogRef = this.dialog.open(AddShowComponent,{
			id: 'add-show',
			maxWidth: '600px',
			width: '100%',
		});

		dialogRef.afterClosed().subscribe((show: any) => {
			if(show){
				show.hidden = false;
				show.platform = 'AUDIO_APP';
				show.timecode_channel = 'none';
				show.processing = true;
				show.created_date_ts = new Date();
				if(show.show_date){
					show.show_date_ts = new Date(show.show_date.split(' ')[0] + "T" +show.show_date.split(' ')[1] + "+00:00");
				}
				const data:any = this.dataSource.data;
				this.dataSource.data = [];
				data.push(show);
				this.dataSource.data = data;
				this.updateFilters();
				const dialogRef = this.dialog.open(LoadingComponent, {
					id: 'adding-show',
					maxWidth: '600px',
					width: '95%',
					data: {
						message: 'Adding your show. This may take a few seconds. You can close this window if desired as processing will occur in the background.',
					}
				});
				this.processShow(show, dialogRef);
			}
		})
	}

	mySingleShow(){
		const dialogRef = this.dialog.open(MySingleShowComponent, {
			id: 'my-single-show',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Single Show',
			}
		});

		dialogRef.componentInstance.singleShowData.subscribe((show: Show) => {
			console.log(show);
			if(show.processing){
				return;
			}
			const dialogRef2 = this.dialog.open(AudioPreviewPopupComponent, {
				id: 'show-details preview-brand',
				maxWidth: '1000px',
				width: '95%',
				data: {
					show: show,
					public: true,
				}
			});

			dialogRef2.componentInstance.onProcessSMPTE.subscribe((show: Show) => {
				this.processSMPTE(show);
				this.onProcessedSMPTE.subscribe(show => {
					dialogRef2.componentInstance.onProcessedSMPTE.emit(show);
				})
			})
			return dialogRef2.afterClosed().subscribe(newValue => {
				if(dialogRef2.componentInstance.wave){
					dialogRef2.componentInstance.wave.stop();
				}
			})
		});
	}

	processShow(show: Show, dialogRef: any){
		if(show.audio_file_id){
			this.showsService.getFileInfo(show.audio_file_id, show).subscribe((response:any) => {
				if(response.file_ID){
					dialogRef.close();
					show.processing = false;
					if(show.add_smpte){
						this.processSMPTE(show);
					}
				}
				else{
					setTimeout(() => {
						this.processShow(show, dialogRef);
					}, 5000)
				}
			}, () => {
				setTimeout(() => {
					this.processShow(show, dialogRef);
				}, 5000)
			});
		}
		else{
			dialogRef.close();
			this.loadData(show);
		}
	}
  ngOnInit(): void {
		this.dataSource.filterPredicate = this.showsFilter();
  }
	editShow(show: any) {
		this.openFieldEditorDialog(show, 'Edit Event').subscribe(newValue => {
			if( newValue.show_id ){
				let data = this.dataSource.data;
				this.dataSource.data = [];
				if(newValue.created_date){
					newValue.created_date_ts = new Date(newValue.created_date.split(' ')[0] + "T" +newValue.created_date.split(' ')[1] + "+00:00");
				}
				if(newValue.show_date){
					newValue.show_date_ts = new Date(newValue.show_date.split(' ')[0] + "T" +newValue.show_date.split(' ')[1] + "+00:00");
				}
				if(newValue.video_url && !newValue.video_filename){
					newValue.video_filename = newValue.video_url.split("/").pop();
				}
				//newValue.status = '--';
				newValue.hidden = show.hidden;
				newValue.platform = show.platform;
				data[data.indexOf(show)] = newValue;
				this.dataSource.data = data;
				this.updateFilters();
				if(newValue.smpte_offset != show.smpte_offset && show.timecode_channel == 'right'){
					this.processSMPTE(newValue);
				}
			}
		});
	}
	editAudioFile(show: any) {
		const dialogRef = this.dialog.open(FileUploadPopupComponent, {
			id: 'file-upload',
			maxWidth: '600px',
			width: '100%',
			data: {
				title: 'Audio File',
				value: null
			}
		});
		dialogRef.afterClosed().subscribe(newValue => {
			if(newValue !== 'cancelDialog' ){
				show.audio_file_id = newValue.audio_file_id;
				show.audio_file_name = newValue.audio_file_name;
				const dialogRef = this.dialog.open(LoadingComponent, {
					id: 'adding-show',
					maxWidth: '600px',
					width: '95%',
					data: {
						message: 'Updating your show. This may take a few seconds. You can close this window if desired as processing will occur in the background.',
					}
				});
				this.updateAudioFile(show, dialogRef);
			}
		})
	}
	updateAudioFile(show: Show, dialogRef: any){
		if(show.audio_file_id){
			this.showsService.getFileInfo(show.audio_file_id, show).subscribe((response:any) => {
				this.showsService.updateAudioFile(show.show_id, show.audio_file_id).subscribe(response => {
					dialogRef.close();
					if(show.timecode_channel === 'right'){
						this.processSMPTE(show);
					}
				})
			}, () => {
				setTimeout(() => {
					this.updateAudioFile(show, dialogRef);
				}, 5000)
			});
		}
	}
	previewVideo(show: any) {
		const dialogRef = this.dialog.open(VideoPreviewPopupComponent, {
			id: 'video-preview',
			maxWidth: '600px',
			width: '100%',
			data: {
				show: show
			}
		});
	}
	deleteVideo(show: any) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'video-delete',
			maxWidth: '600px',
			width: '95%',
			data: {
				title: 'Delete Video',
				message: 'Are you sure you want to delete this video of this show?',
			}
		});
		dialogRef.componentInstance.onConfirm.subscribe(result =>{
			if(result){
				this.showsService.deleteVideo(show.platform, show.show_id).subscribe((response: any) => {
					if(response.status){
						show.video_url = response.video_url;
						show.video_filename = '';
						dialogRef.close();
						this._snackBar.open('The video has successfully deleted', 'Dismiss');
					}

				},
				(errorResponse: HttpErrorResponse) => {
					this._snackBar.open(errorResponse.error.error, 'Dismiss');
				});
			}
		});
	}
	editVideoFile(show: any) {
		const dialogRef = this.dialog.open(VideoUploadPopupComponent, {
			id: 'video-upload',
			maxWidth: '600px',
			width: '100%',
			data: {
				title: 'Video File',
				show: show
			}
		});
		dialogRef.afterClosed().subscribe(newValue => {
			if(newValue.video_url){
				show.video_url = newValue.video_url;
				show.video_filename = newValue.video_filename;
				this._snackBar.open("Video uploaded successfully", "Dismiss");
				setTimeout(() => {
					this._snackBar.dismiss();
				}, 5000)
			}
		})
	}

	repush(show: any) {
		const dialogRef = this.dialog.open(RepushPopupComponent, {
			id: 'repush',
			maxWidth: '600px',
			width: '100%',
			data: {
				title: 'Firebase and Cloudfront Admin',
				show: show
			}
		});
		dialogRef.afterClosed().subscribe(newValue => {
			
		})
	}
	openFieldEditorDialog(value: string, title: string) {
		const dialogRef = this.dialog.open(FieldEditorPopupComponent, {
			id: 'field-editor',
			maxWidth: '600px',
			width: '100%',
			data: {
				title: title,
				value: value
			}
		});
		return dialogRef.afterClosed()
	}
	openLoopingPopup(element: any) {
		// dialogRef.componentInstance.onConfirm.subscribe(() => {
		// 	dialogRef.close();
		// 	this.processSMPTE(show);
		// });
		const loopingRef = this.dialog.open(AlertPopupComponent, {
			id: 'cobra-SMPTE',
			maxWidth: '600px',
			width: '95%',
			data: {
				message: 'Show controls are disabled if the show has Looping enabled. To use show controls for this show, please edit the show and disable the Looping feature.',
			}
		});
	}	
	showDetails(show: Show){
		if(show.processing){
			return;
		}
		let dialogRef = this.dialog.open(AudioPreviewPopupComponent, {
			id: 'show-details',
			maxWidth: '1000px',
			width: '95%',
			data: {
				show: show,
			}
		});
		const dialogRefClose = dialogRef.close;
       	dialogRef.close = () => {
			   console.log(dialogRef.componentInstance.show.status);
			if(dialogRef.componentInstance.show.status === 'play' || dialogRef.componentInstance.show.status === 'resume'){
				const dialogRef2 = this.dialog.open(ConfirmPopupComponent,{
					id: 'confirm-popup',
					maxWidth: '600px',
					width: '100%',
					data: {
						title: 'Confirm',
						message: 'Do you want to stop the show?'
					}
				});
				dialogRef2.componentInstance.onConfirm.subscribe(() => {
					//this.updateShowStatus('stop');
					// if(dialogRef.componentInstance.wave){
					// 	dialogRef.componentInstance.wave.stop();
					// }
					clearTimeout(dialogRef.componentInstance.countdownTimer);
					dialogRef.componentInstance.countdown = 0;
					dialogRef.componentInstance.updateShowStatus('stop');
					dialogRef.close = dialogRefClose;
					dialogRef.close();
					dialogRef2.close();
				})
				dialogRef2.componentInstance.onCancel.subscribe(() => {
					dialogRef.close = dialogRefClose;
					dialogRef.close();  // Close the main dialogRef
					dialogRef2.close(); // Close the confirmation dialog
					console.log('Popup Close')
				});
			}
			else{
				dialogRef.close = dialogRefClose;
				dialogRef.close();
			}
		}
		dialogRef.beforeClosed().subscribe(() => {
			return false;
		});
		dialogRef.componentInstance.onProcessSMPTE.subscribe((show: Show) => {
			this.processSMPTE(show);
			this.onProcessedSMPTE.subscribe(show => {
				dialogRef.componentInstance.onProcessedSMPTE.emit(show);
			})
		})
		return dialogRef.afterClosed().subscribe(newValue => {
			if(dialogRef.componentInstance.wave){
				dialogRef.componentInstance.wave.stop();
			}
		})
	}
	toggleHidden(show: Show){
		if(!show.hidden){
			this.showsService.hide(show.show_id).subscribe((response) => {
				show.hidden = !show.hidden;
				this.updateFilters();
			});
		}
		else{
			this.showsService.unhide(show.show_id).subscribe((response) => {
				show.hidden = !show.hidden;
				this.updateFilters();
			});
		}
		return;
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'show-visibility',
			maxWidth: '600px',
			width: '95%',
			data: {
				title: 'Show Visibility',
				message: 'Are you sure you want to ' + (show.hidden ? 'unhide' : 'hide') + ' this show?',
			}
		});
		dialogRef.componentInstance.onConfirm.subscribe(() =>{
			if(!show.hidden){
				this.showsService.hide(show.show_id).subscribe((response) => {
					show.hidden = !show.hidden;
					this.updateFilters();
					dialogRef.close();
				});
			}
			else{
				this.showsService.unhide(show.show_id).subscribe((response) => {
					show.hidden = !show.hidden;
					this.updateFilters();
					dialogRef.close();
				});
			}
		});
	}
	announcementsPopup(show: Show){
		const dialogRef = this.dialog.open(AnnouncementsComponent, {
			id: 'announcements',
			maxWidth: '550px',
			width: '100%',
			data: {
				show: show,
				title: 'annoucements'
			}
		});
		dialogRef.afterClosed().subscribe(() => {

		});
	}
	addSMPTE(show: Show){
		if(!show.audio_file_id) return;
		if(show.platform === 'CSC'){
			const dialogRef = this.dialog.open(AlertPopupComponent, {
				id: 'cobra-SMPTE',
				maxWidth: '600px',
				width: '95%',
				data: {
					message: 'To add SMPTE to this show, please login to <a href="https://www.cobrashowcreator.com/" target="_blank">COBRA Show Creator</a>. If you open the show, then open the Audio Studio, you can choose Left or Right from the Add SMPTE column for the selected audio file. Once the SMPTE is finished processing, refresh this page and the SMPTE will appear for this show.',
				}
			});
		}
		else {
			const dialogRef = this.dialog.open(ConfirmPopupComponent, {
				id: 'add-SMPTE',
				maxWidth: '600px',
				width: '95%',
				data: {
					title: 'Add SMPTE (Right Channel)',
					message: 'Are you sure you want to Add SMPTE (Right Channel) to this show?',
				}
			});
			dialogRef.componentInstance.onConfirm.subscribe(() => {
				dialogRef.close();
				this.processSMPTE(show);
			});
		}
	}
	processSMPTE(show: Show){
		const dialogRef2 = this.dialog.open(LoadingComponent, {
			id: 'adding-SMPTE',
			maxWidth: '600px',
			width: '95%',
			data: {
				message: 'Adding SMPTE timecode. This may take a few minutes. You can close this window if desired as processing will occur in the background.',
			}
		});
		show.timecode_channel = 'processing';
		this.showsService.addSMPTE(show.audio_file_id, show.platform, show.smpte_offset).subscribe((response) => {
			show.timecode_channel = 'right';
			this.onProcessedSMPTE.emit(show);
			dialogRef2.close();
		});
	}
	removeSMPTE(show: Show){
		if(!show.audio_file_id) return;
		if(show.platform === 'CSC'){
			const dialogRef = this.dialog.open(AlertPopupComponent, {
				id: 'cobra-SMPTE',
				maxWidth: '600px',
				width: '95%',
				data: {
					message: 'To remove SMPTE from this show, please login to <a href="https://www.cobrashowcreator.com/" target="_blank">COBRA Show Creator</a>. If you open the show, then open the Audio Studio, you can choose None from the Add SMPTE column for the selected audio file. Once the SMPTE is finished processing, refresh this page and the SMPTE will appear for this show.',
				}
			});
		}
		else {
			const dialogRef = this.dialog.open(ConfirmPopupComponent, {
				id: 'remove-SMPTE',
				maxWidth: '600px',
				width: '95%',
				data: {
					title: 'Remove SMPTE',
					message: 'Are you sure you want to remove SMPTE from this show?',
				}
			});
			dialogRef.componentInstance.onConfirm.subscribe(() => {
				this.showsService.removeSMPTE(show.audio_file_id, show.platform).subscribe((response) => {
					show.timecode_channel = 'none';
					show.file_url = show.original_file_url
					dialogRef.close();
				});
			});
		}
	}

	preview(show: Show){
		const dialogRef = this.dialog.open(PreviewBrandComponent, {
			id: 'preview-brand',
			maxWidth: '360px',
			width: '100%',
			data: {
				show: show
			}
		});
		dialogRef.afterClosed().subscribe(() => {

		});
	}

	displayQrCode(show: Show){
		const dialogRef = this.dialog.open(QrcodeComponent, {
			id: 'display-qrcode',
			maxWidth: '360px',
			width: '100%',
			data: {
				show: show
			}
		});
		dialogRef.afterClosed().subscribe(() => {

		});
	}

	getAppUrl(show: Show){
		const dialogRef = this.dialog.open(AppLinkComponent, {
			id: 'get-app-url',
			maxWidth: '360px',
			width: '100%',
			data: {
				show: show
			}
		});
		dialogRef.afterClosed().subscribe(() => {

		});
	}
	getSecurityCode(show: Show){
		const dialogRef = this.dialog.open(SecurityCodeComponent, {
			id: 'security-code',
			maxWidth: '480px',
			width: '100%',
			data: {
				show: show
			}
		});
		dialogRef.afterClosed().subscribe(() => {


		});
	}
	showReporting(show: Show){
		const dialogRef = this.dialog.open(ShowReportingComponent, {
			id: 'show-reporting',
			maxWidth: '480px',
			width: '100%',
			data: {
				show: show
			}
		});
		dialogRef.afterClosed().subscribe(() => {
		});
	}
}
