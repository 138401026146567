import {Component, OnInit} from '@angular/core';
import {MessagingService} from "./services/messaging.service";
import { Title } from '@angular/platform-browser';
import {environment} from "../environments/environment";


import {StyleRenderer, ThemeVariables, lyl, WithStyles} from '@alyle/ui';

const STYLES = (theme: ThemeVariables) => ({
	$global: lyl `{
    body {
      background-color: ${theme.background.default}
      color: ${theme.text.default}
      font-family: ${theme.typography.fontFamily}
      margin: 0
      direction: ${theme.direction}
    }
  }`,
	root: lyl `{
    display: block
  }`
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
	providers: [
		StyleRenderer
	]
})
export class AppComponent implements OnInit, WithStyles{
	readonly classes = this.sRenderer.renderSheet(STYLES, true);
  siteTitle = 'Pyrocast';
	favIcon: any = document.querySelector('#appIcon');

	constructor(private messagingService: MessagingService,
							readonly sRenderer: StyleRenderer,
							private title: Title) {
		this.init();
		this.title.setTitle(this.siteTitle);
  }

	ngOnInit() {
		this.messagingService.requestPermission();
		this.messagingService.listen();
		this.init();
	}
	init(){
		if(window.location.hostname == environment.skyDomain){
			document.documentElement.style.setProperty('--primary-color', '#0f57c6');
			this.favIcon.href = 'assets/img/favicon.ico';
			this.siteTitle = 'Skystream Events';
		}
		else if(window.location.hostname == environment.tune2Domain){
			document.documentElement.style.setProperty('--primary-color', '#DE072A');
			document.documentElement.style.setProperty('--accent-color', '#830008');
			this.favIcon.href = 'assets/img/favicon-tune2.ico';
			this.siteTitle = 'Tune2 Admin';
		}
		else{
			this.favIcon.href = 'assets/img/logo-sm.png';
		}
	}
}
