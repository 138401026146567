<div class="edit-brand" [ngStyle]="{'background-color': colorsForm.controls.background_color.value?.rgba ?? brand.background_color }">
    <div class="edit-header">
        <div style="height: 120px;" (click)="editImage('header_image')" [class]="brand.header_image ? 'hover-only' : ''">
					<img [src]="versionedImage(brand.header_image)" *ngIf="brand.header_image" />
					<button class="edit-img-btn gray-btn" mat-raised-button>Edit Header Image</button>
        </div>
    </div>
    <div class="edit-content">
        <button class="page-setting-icon" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Edit colors" style="background: #fff;margin-right: 10px;margin-top: 10px;">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="page-settings" [hasBackdrop]="false" (click)="$event.stopPropagation();" [hasBackdrop]="true">
            <form class="style-form" (ngSubmit)="submitColorForm($event)" [formGroup]="colorsForm" (click)="$event.stopPropagation();">
                <mat-icon class="mat-menu-close" (click)="close()">close</mat-icon>
                <div class="edit-fields" style="display: none">
                    <label>Font Family</label>
                    <div class="color-picker-field font-picker-field-new">
                        <mat-form-field>
                            <input style="color: black !important;" #font_family matInput value="{{font.family}}">
                        </mat-form-field>
                        <mat-icon style="cursor: pointer;" [fpWidth]="'270px'" [fpDialogDisplay]="'popup'" matSuffix [(fontPicker)]="font" [fpPosition]="'left'" [fpCancelButton]="true">font_download</mat-icon>
                    </div>
                </div>
                <div class="edit-fields background-color">
                    <label>Background Color</label>
                    <div class="color-picker-field">
                        <mat-form-field [ngClass]="(colorsForm.controls.background_color.value?.rgba || brand.background_color)?'selected-color':''" [ngStyle]="{'background-color': colorsForm.controls.background_color.value?.rgba ?? brand.background_color, 'color': invertColor(colorsForm.controls.background_color.value?.hex ?? brand.background_color) }">
                            <input
                                matInput
                                [ngxMatColorPicker]="picker1"
                                [formControl]="colorsForm.controls.background_color"
                                autocomplete="off"
                                (click)="picker1.open()"
                            />
                        </mat-form-field>
                        <ngx-mat-color-toggle matSuffix [for]="picker1"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker
                            #picker1
                            [touchUi]="touchUi"
                            [color]="color"
                        ></ngx-mat-color-picker>
                    </div>
                </div>
                <div class="edit-fields heading-color">
                    <label>Heading Text Color</label>
                    <div class="color-picker-field">
                        <mat-form-field [ngClass]="(colorsForm.controls.heading_color.value?.rgba || brand.heading_color)?'selected-color':''" [ngStyle]="{'background-color': colorsForm.controls.heading_color.value?.rgba ?? brand.heading_color, 'color': invertColor(colorsForm.controls.heading_color.value?.hex ?? brand.heading_color) }">
                            <input
                            matInput
                            [ngxMatColorPicker]="picker"
                            [formControl]="colorsForm.controls.heading_color"
                            autocomplete="off"
                            (click)="picker.open()"
                        />
                        </mat-form-field>
                        <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker
                            #picker
                            [touchUi]="touchUi"
                            [color]="color"
                        ></ngx-mat-color-picker>
                    </div>
                </div>
                <div class="edit-fields text-color">
                    <label>General Text Color</label>
                    <div class="color-picker-field">
                        <mat-form-field [ngClass]="(colorsForm.controls.text_color.value?.rgba || brand.text_color)?'selected-color':''" [ngStyle]="{'background-color': colorsForm.controls.text_color.value?.rgba ?? brand.text_color, 'color': invertColor(colorsForm.controls.text_color.value?.hex ?? brand.text_color) }">
                            <input
                            matInput
                            [ngxMatColorPicker]="picker2"
                            [formControl]="colorsForm.controls.text_color"
                            autocomplete="off"
                            (click)="picker2.open()"
                        />
                        </mat-form-field>
                        <ngx-mat-color-toggle matSuffix [for]="picker2"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker
                            #picker2
                            [touchUi]="touchUi"
                            [color]="color"
                        ></ngx-mat-color-picker>
                    </div>
                </div>
							<div class="edit-fields text-color">
								<label>Button Text Color</label>
								<div class="color-picker-field">
									<mat-form-field [ngClass]="(colorsForm.controls.button_text_color.value?.rgba || brand.button_text_color)?'selected-color':''" [ngStyle]="{'background-color': colorsForm.controls.button_text_color.value?.rgba ?? brand.button_text_color, 'color': invertColor(colorsForm.controls.button_text_color.value?.hex ?? brand.button_text_color) }">
										<input
											matInput
											[ngxMatColorPicker]="picker4"
											[formControl]="colorsForm.controls.button_text_color"
											autocomplete="off"
											(click)="picker4.open()"
										/>
									</mat-form-field>
									<ngx-mat-color-toggle matSuffix [for]="picker4"></ngx-mat-color-toggle>
									<ngx-mat-color-picker
										#picker4
										[touchUi]="touchUi"
										[color]="color"
									></ngx-mat-color-picker>
								</div>
							</div>
                <div class="edit-fields text-color">
                    <label>Button Color</label>
                    <div class="color-picker-field">
                        <mat-form-field [ngClass]="(colorsForm.controls.button_color.value?.rgba || brand.button_color)?'selected-color':''" [ngStyle]="{'background-color': colorsForm.controls.button_color.value?.rgba ?? brand.button_color, 'color': invertColor(colorsForm.controls.button_color.value?.hex ?? brand.button_color) }">
                            <input
                            matInput
                            [ngxMatColorPicker]="picker3"
                            [formControl]="colorsForm.controls.button_color"
                            autocomplete="off"
                            (click)="picker3.open()"
                        />
                        </mat-form-field>
                        <ngx-mat-color-toggle matSuffix [for]="picker3"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker
                            #picker3
                            [touchUi]="touchUi"
                            [color]="color"
                        ></ngx-mat-color-picker>
                    </div>
                </div>
                <div class="edit-fields text-color">
									<button mat-raised-button (click)="close()" [disabled]="load" class="gray-btn" style="margin-right: 15px;">
										Cancel
									</button>
                    <button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" type="submit" [disabled]="load">
                        Save
                        <mat-icon *ngIf="load">
                            <mat-spinner color="accent" diameter="20"></mat-spinner>
                        </mat-icon>
                    </button>
                </div>
            </form>
        </mat-menu>
        <h3> <b [ngStyle]="{'color': colorsForm.controls.heading_color.value?.rgba ?? brand.heading_color, 'font-family': font.family}"> Event Name </b>
            <span [ngStyle]="{'color': colorsForm.controls.text_color.value?.rgba ?? brand.text_color, 'font-family': font.family }">www.website.com</span>
        </h3>
        <span [ngStyle]="{'color': colorsForm.controls.text_color.value?.rgba ?? brand.text_color, 'font-family': font.family }">Event Address Line 1</span>
        <span [ngStyle]="{'color': colorsForm.controls.text_color.value?.rgba ?? brand.text_color, 'font-family': font.family }">Event Address Line 2</span>
        <span style="margin-bottom: 20px;" [ngStyle]="{'color': colorsForm.controls.text_color.value?.rgba ?? brand.text_color, 'font-family': font.family }">Event City, Event State, Event Country</span>

        <h3 [ngStyle]="{'color': colorsForm.controls.heading_color.value?.rgba ?? brand.heading_color, 'font-family': font.family }">January 1st, 2022, at 9:00pm EST</h3>
        <p [ngStyle]="{'color': colorsForm.controls.text_color.value?.rgba ?? brand.text_color, 'font-family': font.family }">Allow a detailed description of the event.</p>
        <p [ngStyle]="{'color': colorsForm.controls.text_color.value?.rgba ?? brand.text_color, 'font-family': font.family }">Allow a detailed description of the event.</p>
        <p [ngStyle]="{'color': colorsForm.controls.text_color.value?.rgba ?? brand.text_color, 'font-family': font.family }">Allow a detailed description of the event.</p>
        <p [ngStyle]="{'color': colorsForm.controls.text_color.value?.rgba ?? brand.text_color, 'font-family': font.family }">Allow a detailed description of the event.</p>

        <div class="event-button">
            <button mat-raised-button [ngStyle]="{'background-color': (colorsForm.controls.button_color.value?.rgba || '#2cbcf4') ?? brand.button_color , 'color': (colorsForm.controls.button_text_color.value?.rgba || '#ffffff') ?? brand.button_text_color, 'font-family': font.family}">Listen to Show</button>
				</div>
				<div class="event-button">
            <button mat-raised-button [ngStyle]="{'background-color': (colorsForm.controls.button_color.value?.rgba || '#2cbcf4') ?? brand.button_color, 'color': (colorsForm.controls.button_text_color.value?.rgba || '#ffffff') ?? brand.button_text_color, 'font-family': font.family}">Map Directions</button>
				</div>
				<div class="event-button">
						<button mat-raised-button [ngStyle]="{'background-color': (colorsForm.controls.button_color.value?.rgba || '#2cbcf4') ?? brand.button_color, 'color': (colorsForm.controls.button_text_color.value?.rgba || '#ffffff') ?? brand.button_text_color, 'font-family': font.family}">Back to Menu</button>
        </div>
    </div>
    <div class="edit-footer">
			<div style="height: 90px;" (click)="editImage('footer_image')" [class]="brand.header_image ? 'hover-only' : ''">
				<img [src]="versionedImage(brand.footer_image)" *ngIf="brand.footer_image" />
				<button class="edit-img-btn gray-btn" mat-raised-button>Edit Footer Image</button>
			</div>
    </div>
</div>
