import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import {Observable} from "rxjs";
import {LinkedUser} from "../interfaces/linked-user.interface";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LinkedUsersService {

	constructor(private http:HttpClient) { }

  index(
    filters: any, orderBy = '', order = 'asc',
    pageNumber = 1, pageSize = 10):  Observable<any> {
		let params = new HttpParams();
  	Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/linked_users', {
      params: params
    });
  }

	get(id: number): Observable<Object>{
		return this.http.get(environment.apiUrl + '/linked_users/'+id);
	}

	storeOrEdit(linkedUser: LinkedUser): Observable<Object> {
    return linkedUser.id ? this.edit(linkedUser) : this.store(linkedUser);
  }

	store(linkedUser: LinkedUser): Observable<Object> {
		return this.http.post(environment.apiUrl + '/linked_users/', linkedUser);
	}

	edit(linkedUser: LinkedUser): Observable<Object> {
		return this.http.put(environment.apiUrl + '/linked_users/'+linkedUser.id, linkedUser);
	}

	destroy(id: number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/linked_users/'+id);
	}
}
