import {Component, Inject, OnInit} from '@angular/core';
import {Show} from "../../interfaces/show.interface";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ShowsService} from "../../services/shows.service";

@Component({
  selector: 'app-app-link',
  templateUrl: './app-link.component.html',
  styleUrls: ['./app-link.component.scss']
})
export class AppLinkComponent implements OnInit {

	show: Show;
	qrcodeUrl = ''
	processing = true;
	appLink = '';
	constructor(private dialogRef: MatDialogRef<AppLinkComponent>,
							@Inject(MAT_DIALOG_DATA) data:any,
							private showService: ShowsService) {
		this.show = data.show;
		this.showService.getAppUrl(this.show).subscribe((response:any) => {
			this.processing = false;
			if(response.status && response.status === 'success'){
				this.appLink = response.shortLink;
				this.qrcodeUrl = 'https://qrcode.tec-it.com/API/QRCode?data=' + encodeURIComponent(this.appLink) + '&backcolor=%23ffffff&method=download';
			}
		});
	}

	ngOnInit(): void {
	}

	cancel() {
		this.dialogRef.close(false);
	}

}
