import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {fromEvent} from "rxjs";
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";
import {BrandsService} from "../services/brands.service";
import {AuthService} from "../services/auth/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {Brand} from "../interfaces/brand.interface";
import {environment} from "../../environments/environment";
import {AddBrandComponent} from "../brands/add-brand/add-brand.component";
import { EditBrandComponent } from '././edit-brand/edit-brand.component';
import {LoadingComponent} from "../common/loading/loading.component";
import { ConfirmPopupComponent } from '../common/confirm-popup/confirm-popup.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {EditBrandNameComponent} from "./edit-brand-name/edit-brand-name.component";

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {

	displayedColumns: string[] = ['name', 'created_at', 'action'];
	dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
	s: string = '';
	total_brands = 0;
	status = [];
	loading = false;

	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort)sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

	ngAfterViewInit() {
		this.paginator._intl.itemsPerPageLabel="Brands per page:";
		this.dataSource.sort = this.sort;
		this.dataSource.paginator = this.paginator;
		fromEvent(this.search.nativeElement,'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;
					this.updateFilters();
				})
			)
			.subscribe();
	}

	updateFilters() {
		this.dataSource.filter = JSON.stringify({ s: this.s });
	}

	brandsFilter() {
		let filterFunction = function (data: any, filter: string): boolean {
			let filters = JSON.parse(filter);
			let match = true;
			return match && ( data.name && data.name.toString().toLowerCase().trim().indexOf(filters.s.toLowerCase()) !== -1);
		}
		return filterFunction
	}

	constructor(public brandsService: BrandsService,
							public auth: AuthService,
							public dialog: MatDialog,
							private cdr: ChangeDetectorRef,
							public _snackBar: MatSnackBar) {
		this.loadData();
	}
	loadData(newBrand:any = null) {
		this.loading = true;
		this.dataSource.data = [];
		this.brandsService.index({}, 'created_at', 'desc', 1, 1000).subscribe(data => {
			this.loading = false;
			data.map( (brand: Brand) => {
				return brand;
			})
			this.dataSource.data = data;
			this.total_brands = this.dataSource.filteredData.length
		}, error => {
			if(error.error.error.code){
				this.auth.logout().then(status => {
					window.location.href = environment.appUrl;
				});
			}
		})
	}
	add() {
		const dialogRef = this.dialog.open(AddBrandComponent,{
			id: 'add-brand',
			maxWidth: '600px',
			width: '100%',
		});
		dialogRef.afterClosed().subscribe((brand: any) => {
			if(brand){
				const data:any = this.dataSource.data;
				this.dataSource.data = [];
				data.push(brand);
				this.dataSource.data = data;
				this.edit(brand);
			}
		})
	}

	editName(brand: any) {
		const dialogRef = this.dialog.open(EditBrandNameComponent,{
			id: 'edit-brand-name',
			maxWidth: '600px',
			width: '100%',
			data: {
				brand: brand
			}
		});
		dialogRef.afterClosed().subscribe((updatedBrand: any) => {
			if(updatedBrand){
				console.log("inn");
				const data:any = this.dataSource.data;
				this.dataSource.data = [];
				const i = data.indexOf(brand);
				if(i !== -1){
					data[i] = updatedBrand;
				}
				this.dataSource.data = data;
				// this.edit(brand);
			}
		})
	}

	edit(brand: any) {
		const dialogRef = this.dialog.open(EditBrandComponent, {
			id: 'edit-brand',
			maxWidth: '360px',
			width: '100%',
			data: {
				brand: brand
			}
		});
		dialogRef.componentInstance.onUpdate.subscribe(() => {
			this.loadData();
		});
	}


	delete(id: number) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'delete-brand',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to delete this brand?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.brandsService.destroy(id).subscribe(() => {
				dialogRef.close()
				this.loadData();
			});
		});
	}

	ngOnInit(): void {
		this.dataSource.filterPredicate = this.brandsFilter();
	}
}
